<template>
  <div class="container">
    <template v-if="isMobile == 0">
      <div class="header">
        <router-link class="btn" to="/stocks">{{
          $t("aside.list")[1]
        }}</router-link>
        <i class="iconfont2 icon-right_arrow"></i>
        <span>{{ $t("common.subManage") }}</span>
      </div>
      <div class="content">
        <div class="assetsBox">
          <div class="title">
            <div class="tab" @click="pre">
              {{
                selectNum == 1
                  ? $t("subManage.notUseAssets")
                  : selectNum == 2
                  ? $t("subManage.useAssets")
                  : $t("subManage.totalAssets")
              }}
            </div>

            <div class="arrow left" @click="pre">
              <i class="iconfont2 icon-right_arrow"></i>
            </div>

            <div class="tab active">
              {{
                selectNum == 1
                  ? $t("subManage.useAssets")
                  : selectNum == 2
                  ? $t("subManage.totalAssets")
                  : $t("subManage.notUseAssets")
              }}
            </div>

            <div class="arrow" @click="next">
              <i class="iconfont2 icon-right_arrow"></i>
            </div>

            <div class="tab" @click="next">
              {{
                selectNum == 1
                  ? $t("subManage.totalAssets")
                  : selectNum == 2
                  ? $t("subManage.notUseAssets")
                  : $t("subManage.useAssets")
              }}
            </div>
          </div>
          <div class="money">
            <template
              v-if="
                (!!attached.erc20 || !!attached.nft) &&
                (Object.keys(attached.erc20).length > 0 ||
                  Object.keys(attached.nft).length > 0) &&
                selectNum == 2
              "
            >
              <div
                class="data_box"
                v-if="
                  !!attached.erc20 && Object.keys(attached.erc20).length > 0
                "
              >
                <!-- 常规资产 -->
                <div
                  class="item"
                  v-for="(item, key, index) in attached.erc20"
                  :key="index"
                >
                  {{ item }}
                  <sub class="company">{{ key.toUpperCase() }}</sub>
                </div>
              </div>

              <div class="data_box nft" v-if="web == 'opensea'">
                <!-- nft资产 -->
                <div class="type_title">NFT:</div>
                <template
                  v-if="!!attached.nft && Object.keys(attached.nft).length > 0"
                >
                  <div
                    class="item"
                    v-for="(item, key, index) in attached.nft"
                    :key="index"
                  >
                    {{ item }}
                    <sub class="company">{{ key.toUpperCase() }}</sub>
                  </div>
                </template>
                <template v-else>
                  <div class="item no_data no_data_nft">
                    {{ $t("common.noData") }}
                  </div>
                </template>
              </div>
            </template>
            <template
              v-else-if="
                (!!use_stat.erc20 || !!use_stat.nft) &&
                (Object.keys(use_stat.erc20).length > 0 ||
                  Object.keys(use_stat.nft).length > 0) &&
                selectNum == 1
              "
            >
              <div
                class="data_box"
                v-if="
                  !!use_stat.erc20 && Object.keys(use_stat.erc20).length > 0
                "
              >
                <!-- 常规资产 -->
                <div
                  class="item"
                  v-for="(item, key, index) in use_stat.erc20"
                  :key="index"
                >
                  {{ item }}
                  <sub class="company">{{ key.toUpperCase() }}</sub>
                </div>
              </div>

              <div class="data_box nft" v-if="web == 'opensea'">
                <!-- nft资产 -->
                <div class="type_title">NFT:</div>
                <template
                  v-if="!!use_stat.nft && Object.keys(use_stat.nft).length > 0"
                >
                  <div
                    class="item"
                    v-for="(item, key, index) in use_stat.nft"
                    :key="index"
                  >
                    {{ item }}
                    <sub class="company">{{ key.toUpperCase() }}</sub>
                  </div>
                </template>
                <template v-else>
                  <div class="item no_data no_data_nft">
                    {{ $t("common.noData") }}
                  </div>
                </template>
              </div>
            </template>
            <template
              v-else-if="
                (!!not_use_stat.erc20 || !!not_use_stat.nft) &&
                (Object.keys(not_use_stat.erc20).length > 0 ||
                  Object.keys(not_use_stat.nft).length > 0) &&
                selectNum == 3
              "
            >
              <div
                class="data_box"
                v-if="
                  !!not_use_stat.erc20 &&
                  Object.keys(not_use_stat.erc20).length > 0
                "
              >
                <!-- 常规资产 -->
                <div
                  class="item"
                  v-for="(item, key, index) in not_use_stat.erc20"
                  :key="index"
                >
                  {{ item }}
                  <sub class="company">{{ key.toUpperCase() }}</sub>
                </div>
              </div>

              <div class="data_box nft" v-if="web == 'opensea'">
                <!-- nft资产 -->
                <div class="type_title">NFT:</div>
                <template
                  v-if="
                    !!not_use_stat.nft &&
                    Object.keys(not_use_stat.nft).length > 0
                  "
                >
                  <div
                    class="item"
                    v-for="(item, key, index) in not_use_stat.nft"
                    :key="index"
                  >
                    {{ item }}
                    <sub class="company">{{ key.toUpperCase() }}</sub>
                  </div>
                </template>
                <template v-else>
                  <div class="item no_data no_data_nft">
                    {{ $t("common.noData") }}
                  </div>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="data_box">
                <div class="item no_data">{{ $t("common.noData") }}</div>
              </div>
            </template>
          </div>
        </div>
        <div class="tabList">
          <div
            :class="{ active: selectTool == 'wallet' }"
            @click="openTool('wallet')"
          >
            <span class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/wallet.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/wallet_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/wallet_active.png')"
              />
            </span>
            {{ $t("tools.wallet.name") }}
          </div>
          <!-- 导入交易钱包 -->
          <div
            :class="{ active: selectTool == 'importWallet' }"
            @click="openImport"
          >
            <span class="icon iconImport">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/import.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/import_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/import_active.png')"
              />
            </span>
            {{ $t("tools.importWallet.name") }}
          </div>
          <div
            :class="{ active: selectTool == 'distribution' }"
            @click="openTool('distribution')"
          >
            <span class="icon iconDistribution">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/distribution.png')"
              />
              <img
                v-else
                class="img"
                :src="
                  require('@/assets/images/subManage/distribution_light.png')
                "
              />
              <img
                class="img_active"
                :src="
                  require('@/assets/images/subManage/distribution_active.png')
                "
              />
            </span>
            {{ $t("tools.distribution.name") }}
          </div>
          <div
            :class="{ active: selectTool == 'pool' }"
            @click="openTool('pool')"
          >
            <span class="icon iconPool">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/pool.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/pool_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/pool_active.png')"
              />
            </span>
            {{ $t("tools.pool.name") }}
          </div>
          <div
            :class="{ active: selectTool == 'empower' }"
            @click="openTool('empower')"
          >
            <div class="icon">
              <i class="iconfont2 icon-shouquan"></i>
            </div>
            <div>{{ $t("tools.empower.name2") }}</div>
          </div>
          <div :class="{ active: selectTool == 'refresh' }" @click="refresh">
            <span class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/refresh.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/refresh_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/refresh_active.png')"
              />
            </span>
            {{ $t("tools.refresh.name") }}
          </div>
        </div>
        <!-- 资金钱包 -->
        <div class="main_wallet">
          <div class="walletTitle">
            <i class="iconfont2 icon-wallet"></i
            >{{ $t("subManage.mainWallet") }}
          </div>
          <div class="list">
            <div class="hd">
              <div class="address">{{ $t("subManage.address") }}</div>
              <div class="exchange">{{ $t("common.exchange") }}</div>
              <div class="chain">{{ $t("subManage.chain") }}</div>
              <div class="assets">{{ $t("subManage.assets") }}</div>
              <!-- 授权代币列表 -->
              <div class="approveTokens" v-if="web != 'opensea'">
                {{ $t("subManage.approveTokens") }}
              </div>

              <div class="assets" v-if="web == 'opensea'">
                {{ $t("subManage.assets") }}(NFT)
              </div>
              <!-- 交易限制 -->
              <div class="restriction">
                {{ $t("subManage.transactionRestriction") }}
              </div>
              <!-- 参与下单 -->
              <div
                class="participate"
                v-if="web != 'opensea' || subWalletList.length"
              >
                {{ $t("subManage.participate") }}
              </div>
              <!-- 刷新 -->
              <div class="refresh">{{ $t("common.refresh") }}</div>
              <!-- 资金划分 -->
              <div class="transfer">{{ $t("subManage.transfer") }}</div>
            </div>
            <div class="bd">
              <div class="bd_box">
                <template v-if="Object.keys(mainWallet).length > 0">
                  <div class="row">
                    <div class="item">
                      <div class="address">
                        <el-tooltip
                          effect="dark"
                          :content="$t('subManage.copyAddress')"
                          placement="top"
                          :enterable="false"
                        >
                          <i
                            class="iconfont2 icon-jilu1 copyBtn"
                            @click="handleCopy(mainWallet.address)"
                          ></i>
                        </el-tooltip>
                        <el-tooltip
                          effect="dark"
                          :content="mainWallet.address"
                          placement="top"
                          :enterable="false"
                        >
                          <span>{{ addressFormat(mainWallet.address) }}</span>
                        </el-tooltip>
                      </div>
                      <div class="exchange">{{ mainWallet.web_name }}</div>
                      <div class="chain">{{ mainWallet.chain }}</div>
                      <div class="assets" v-html="mainWallet.assets"></div>

                      <!-- 授权代币列表 -->
                      <div
                        class="approveTokens approveTokensCon"
                        v-if="web != 'opensea'"
                      >
                        <template
                          v-if="
                            !mainWallet.approve_list ||
                            mainWallet.approve_list.length > 0
                          "
                        >
                          <div
                            v-for="(item, index) in mainWallet.approve_list"
                            :key="index"
                          >
                            {{ item }}
                          </div>
                        </template>
                        <template v-else> / </template>
                      </div>
                      <!-- NFT-资产小结 -->
                      <div class="assets" v-if="web == 'opensea'">
                        <template v-if="!!mainWallet.assets_nft">
                          <div v-html="mainWallet.assets_nft"></div>
                          <div
                            class="btn_box"
                            v-if="boxNum.indexOf(mainWallet.address) < 0"
                          >
                            <el-tooltip
                              effect="dark"
                              :content="$t('subManage.openBtnTip')"
                              placement="top"
                              :enterable="false"
                            >
                              <span
                                class="btn"
                                @click="
                                  showDetail(mainWallet.id, mainWallet.address)
                                "
                                >{{ $t("subManage.openBtn") }}</span
                              >
                            </el-tooltip>
                          </div>
                          <div class="btn_box" v-else>
                            <span
                              class="btn"
                              @click="closeDetail(mainWallet.address)"
                              >{{ $t("subManage.closeBtn") }}</span
                            >
                          </div>
                        </template>
                        <template v-else>
                          <span style="color: #e65b1b">/</span>
                        </template>
                      </div>
                      <!-- 交易限制 -->
                      <div
                        class="restriction btn"
                        @click="
                          openRestriction(
                            mainWallet.id,
                            $t('subManage.mainWallet'),
                            mainWallet.trade_type,
                            'main'
                          )
                        "
                      >
                        {{
                          mainWallet.trade_type == 1
                            ? $t("subManage.buyOnly")
                            : mainWallet.trade_type == 2
                            ? $t("subManage.sellOnly")
                            : $t("subManage.unlimited")
                        }}
                        <i class="iconfont2 icon-restriction"></i>
                      </div>
                      <!-- 参与下单 -->
                      <div
                        class="participate"
                        v-if="web != 'opensea' || subWalletList.length"
                      >
                        <el-switch
                          @change="switchChange(mainWallet)"
                          active-color="#E65B1B"
                          inactive-color="#e9e9e9"
                          active-value="1"
                          inactive-value="0"
                          v-model="mainWallet.state"
                        >
                        </el-switch>
                      </div>
                      <!-- 刷新 -->
                      <div class="refresh img" @click="mainRefresh">
                        <img
                          class="img"
                          :src="
                            require('@/assets/images/subManage/refresh_active.png')
                          "
                        />
                      </div>
                      <div
                        class="transfer img"
                        @click="openPopAssets(mainWallet, 'main')"
                      >
                        <img
                          :src="
                            require('@/assets/images/subManage/distribution_active.png')
                          "
                        />
                      </div>
                    </div>
                    <!-- NFT-资产详情 -->
                    <div
                      class="item_detail_box"
                      :ref="mainWallet.address"
                      v-if="web == 'opensea' && !!mainWallet.assets_nft"
                    >
                      <div class="item_detail">
                        <div
                          class="detail_item_box"
                          v-for="(nft, nft_index) in boxDataList[
                            mainWallet.address
                          ]"
                          :key="nft_index"
                        >
                          <div class="img">
                            <!-- <img :src="nft.token_image" alt=""> -->
                            <el-image :src="nft.token_image" fit="contain">
                              <div slot="placeholder" class="image-slot">
                                {{ $t("common.loding")
                                }}<span class="dot">...</span>
                              </div>
                            </el-image>
                            <div class="label">{{ nft.contract_type }}</div>
                          </div>
                          <!-- 如果没有图片 -->
                          <!-- <div class="img">
                                                        <el-tooltip effect="dark" :content="nft.token_image" placement="top">
                                                            <div class="value">{{nft.token_image}}</div>
                                                        </el-tooltip>
                                                    </div> -->
                          <div class="detail_row">
                            <span class="label"> token: </span>
                            <span class="value">
                              <el-tooltip
                                effect="dark"
                                :content="nft.token"
                                placement="top"
                              >
                                <!-- <el-tooltip effect="dark" content="nft.token" placement="top"> -->
                                <div class="value">{{ nft.token }}</div>
                                <!-- <div class="value">nft.token</div> -->
                              </el-tooltip>
                            </span>
                          </div>
                          <div class="detail_row">
                            <span class="label"> token ID: </span>
                            <span class="value">
                              <el-tooltip
                                effect="dark"
                                :content="nft.token_id"
                                placement="top"
                              >
                                <!-- <el-tooltip effect="dark" content="nft.token_id" placement="top"> -->
                                <div class="value">{{ nft.token_id }}</div>
                                <!-- <div class="value">nft.token_id</div> -->
                              </el-tooltip>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        class="closeDetailBtn"
                        v-if="boxNum.indexOf(mainWallet.address) >= 0"
                      >
                        <span
                          class="btn"
                          @click="closeDetail(mainWallet.address)"
                          >{{ $t("subManage.closeBtn") }}</span
                        >
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="no_data">{{ $t("common.noData") }}</div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- 交易钱包筛选区域 -->
        <div class="subWalletSearch">
          <div class="headTitle">
            <i class="iconfont2 icon-shaixuan"></i>
            {{ $t("subManage.filterSearch") }}
          </div>
          <div class="searchBox">
            <div class="search">
              <el-select
                v-model="source"
                :placeholder="$t('subManage.source')"
                @change="getSubWalletsListLoad"
              >
                <el-option :label="$t('common.whole')" value="0"></el-option>
                <el-option
                  :label="$t('subManage.onlyCreate')"
                  value="1"
                ></el-option>
                <el-option
                  :label="$t('subManage.onlyImport')"
                  value="2"
                ></el-option>
              </el-select>
              <el-select
                v-model="listRestriction"
                :placeholder="$t('subManage.transactionRestriction')"
                @change="getSubWalletsListLoad"
              >
                <el-option :label="$t('common.whole')" value="-1"></el-option>
                <el-option
                  :label="$t('subManage.unlimited')"
                  value="0"
                ></el-option>
                <el-option
                  :label="$t('subManage.buyOnly')"
                  value="1"
                ></el-option>
                <el-option
                  :label="$t('subManage.sellOnly')"
                  value="2"
                ></el-option>
              </el-select>

              <div class="groupsBox">
                {{ $t("subManage.groupFilter") }}：
                <div class="checkbox">
                  <el-checkbox-group
                    v-model="groups"
                    @change="getSubWalletsListLoad"
                  >
                    <el-checkbox
                      :label="item.id"
                      v-for="(item, index) in groupsList"
                      :key="index"
                    >
                      {{ item.name }}</el-checkbox
                    >
                  </el-checkbox-group>
                </div>
                <div class="btnBox">
                  <div class="btn" @click="openAddGroups">
                    <i class="iconfont2 icon-roundadd"></i>
                    {{ $t("common.add2") }}
                  </div>
                  <span class="line"></span>
                  <div
                    class="btn"
                    :class="{ disable_btn: groupsList.length <= 1 }"
                    @click="openDelGroups(groupsList.length <= 1)"
                  >
                    <i class="iconfont2 icon-close-circle"></i>
                    {{ $t("common.del") }}
                  </div>
                </div>
              </div>
            </div>
            <div class="g_btn btn" @click="exportAdd">
              {{ $t("subManage.subWalletExport") }}
            </div>
          </div>
        </div>
        <!-- 交易钱包列表 -->
        <div class="sub_wallet">
          <div class="walletTitle">
            <i class="iconfont2 icon-wallet"></i>
            {{ $t("subManage.subWalletList") }}
            <span>
              {{
                "(" +
                (!source ? $t("common.enabled") : $t("subManage.enabled")) +
                "：" +
                enable +
                "，" +
                $t("common.total") +
                "：" +
                count +
                ")"
              }}
            </span>
          </div>
          <div class="search">
            <!-- 钱包来源筛选及批量开关 -->
            <div
              class="g_btn btn"
              :class="{
                g_btn_plain_disable: selectList.length == 0 || !hasEnable,
              }"
              @click="setAttachedAll(1, selectList.length == 0 || !hasEnable)"
            >
              {{ $t("subManage.allOpen") }}
            </div>
            <div
              class="g_btn btn"
              :class="{
                g_btn_plain_disable: selectList.length == 0 || !hasClose,
              }"
              @click="setAttachedAll(0, selectList.length == 0 || !hasClose)"
            >
              {{ $t("subManage.allClose") }}
            </div>

            <div
              class="g_btn btn"
              :class="{ g_btn_plain_disable: selectList.length == 0 }"
              @click="setAttachedAll(2, selectList.length == 0)"
            >
              {{ $t("subManage.selectDelete") }}
            </div>

            <span class="line"></span>
            <!-- 交易限制筛选及设定 -->
            <div
              class="g_btn btn"
              :class="{
                g_btn_plain_disable: selectList.length == 0 || !hasLimit,
              }"
              @click="setRestriction(0, selectList.length == 0 || !hasLimit)"
            >
              {{ $t("subManage.unlimited") }}
            </div>
            <div
              class="g_btn btn"
              :class="{
                g_btn_plain_disable: selectList.length == 0 || !hasBuyOnly,
              }"
              @click="setRestriction(1, selectList.length == 0 || !hasBuyOnly)"
            >
              {{ $t("subManage.buyOnly") }}
            </div>
            <div
              class="g_btn btn"
              :class="{
                g_btn_plain_disable: selectList.length == 0 || !hasSellOnly,
              }"
              @click="setRestriction(2, selectList.length == 0 || !hasSellOnly)"
            >
              {{ $t("subManage.sellOnly") }}
            </div>

            <span class="line"></span>
            <!-- 替换分组 -->
            <div
              class="g_btn btn"
              :class="{ g_btn_plain_disable: selectList.length == 0 }"
              @click="openMoveGroups(selectList.length == 0)"
            >
              {{ $t("subManage.replaceGroup") }}
            </div>
          </div>
          <div class="list">
            <!-- 分页 -->
            <div class="pagination" :style="{ paddingTop: 0 }">
              <el-pagination
                background
                layout="sizes, prev, pager, next"
                :total="count"
                :page-size="pageNum"
                :page-sizes="[20, 50, 100]"
                :current-page="page"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
              >
              </el-pagination>
            </div>
            <el-table
              ref="multipleTable"
              :data="subWalletList"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              :row-key="
                (row) => {
                  return row.id;
                }
              "
            >
              <el-table-column width="20"></el-table-column>

              <el-table-column
                :reserve-selection="true"
                type="selection"
                width="30"
              >
              </el-table-column>

              <el-table-column
                :label="$t('subManage.address')"
                min-width="16"
                class-name="address"
              >
                <template slot-scope="scope">
                  <div class="address_box">
                    <el-tooltip
                      effect="dark"
                      :content="$t('subManage.copyAddress')"
                      placement="top"
                      :enterable="false"
                    >
                      <i
                        class="iconfont2 icon-jilu1 copyBtn"
                        @click="handleCopy(scope.row.address)"
                      ></i>
                    </el-tooltip>
                    <el-tooltip
                      effect="dark"
                      :content="scope.row.address"
                      placement="top"
                      :enterable="false"
                    >
                      <span class="g_word_break">{{
                        addressFormat(scope.row.address)
                      }}</span>
                    </el-tooltip>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('subManage.source')"
                min-width="7"
                class-name="source"
              >
                <template slot-scope="scope">
                  <div class="source_value">
                    {{
                      scope.row.source == 1
                        ? $t("subManage.create")
                        : scope.row.source == 2
                        ? $t("subManage.import")
                        : scope.row.source
                    }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('common.exchange')"
                min-width="7"
                class-name="exchange"
                v-if="mainWallet.web_name"
              >
                {{ mainWallet.web_name }}
              </el-table-column>
              <el-table-column
                :label="$t('subManage.chain')"
                min-width="6"
                class-name="chain"
                v-if="mainWallet.chain"
              >
                {{ mainWallet.chain }}
              </el-table-column>
              <el-table-column
                :label="$t('subManage.assets')"
                min-width="18"
                class-name="assets"
              >
                <template slot-scope="scope">
                  <div v-html="scope.row.assets"></div>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('subManage.approveTokens')"
                min-width="10"
                class-name="approveTokens"
                v-if="web != 'opensea'"
              >
                <template slot-scope="scope">
                  <div class="approveTokensCon" v-if="web != 'opensea'">
                    <template
                      v-if="
                        !scope.row.approve_list ||
                        scope.row.approve_list.length > 0
                      "
                    >
                      <div
                        v-for="(it, index) in scope.row.approve_list"
                        :key="index"
                      >
                        {{ it }}
                      </div>
                    </template>
                    <template v-else> / </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                v-if="web == 'opensea'"
                :label="$t('subManage.assets') + '(NFT)'"
                min-width="18"
                class-name="assets"
              >
                <template slot-scope="scope">
                  <template v-if="!!scope.row.assets_nft">
                    <div v-html="scope.row.assets_nft"></div>
                    <div
                      class="btn_box"
                      v-if="boxNum.indexOf(scope.row.address) < 0"
                    >
                      <el-tooltip
                        effect="dark"
                        :content="$t('subManage.openBtnTip')"
                        placement="top"
                        :enterable="false"
                      >
                        <span
                          class="btn"
                          @click="
                            showDetail(scope.row.id, scope.row.address, 'sub')
                          "
                          >{{ $t("subManage.openBtn") }}</span
                        >
                      </el-tooltip>
                    </div>
                    <div class="btn_box" v-else>
                      <span
                        class="btn"
                        @click="closeDetail(scope.row.address, 'sub')"
                        >{{ $t("subManage.closeBtn") }}</span
                      >
                    </div>
                  </template>
                  <template v-else>
                    <span style="color: #e65b1b">/</span>
                  </template>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('subManage.transactionRestriction')"
                min-width="7"
                class-name="restriction"
              >
                <template slot-scope="scope">
                  {{
                    scope.row.trade_type == 1
                      ? $t("subManage.buyOnly")
                      : scope.row.trade_type == 2
                      ? $t("subManage.sellOnly")
                      : $t("subManage.unlimited")
                  }}
                </template>
              </el-table-column>
              <!-- 当前分组 -->
              <el-table-column
                :label="$t('subManage.currentGroup')"
                min-width="7"
                class-name="restriction"
              >
                <template slot-scope="scope">
                  {{ scope.row.group_name.name }}
                </template>
              </el-table-column>
              <el-table-column min-width="6">
                <template slot="header">
                  <div class="enable" @click="switchSort">
                    {{ $t("subManage.enable") }}
                    <div class="switch_icon">
                      <i class="iconfont2 icon-qiehuan"></i>
                    </div>
                  </div>
                </template>
                <template slot-scope="scope">
                  <el-switch
                    @change="switchChange(scope.row)"
                    active-color="#E65B1B"
                    inactive-color="#e9e9e9"
                    active-value="1"
                    inactive-value="0"
                    v-model="scope.row.state"
                  >
                  </el-switch>
                </template>
              </el-table-column>
              <el-table-column
                :label="$t('subManage.transfer')"
                min-width="5"
                class-name="assets"
              >
                <template slot-scope="scope">
                  <div
                    class="transfer img"
                    @click="openPopAssets(scope.row, 'sub')"
                  >
                    <img
                      :src="
                        require('@/assets/images/subManage/distribution_active.png')
                      "
                    />
                  </div>
                </template>
              </el-table-column>

              <el-table-column width="12"></el-table-column>
            </el-table>
            <!-- 分页 -->
            <div class="pagination">
              <el-pagination
                background
                layout="sizes, prev, pager, next"
                :total="count"
                :page-size="pageNum"
                :page-sizes="[20, 50, 100]"
                :current-page="page"
                @current-change="handleCurrentChange"
                @size-change="handleSizeChange"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="isMobile == 1">
      <div class="content_phone">
        <van-swipe
          class="assetsBox"
          :autoplay="6000"
          :indicator-color="theme != 'light' ? 'white' : 'black'"
          :initial-swipe="1"
        >
          <van-swipe-item>
            <div class="swipe_item">
              <div class="title">{{ $t("subManage.useAssets") }}</div>
              <div
                class="money"
                v-if="
                  (!!use_stat.erc20 || !!use_stat.nft) &&
                  (Object.keys(use_stat.erc20).length > 0 ||
                    Object.keys(use_stat.nft).length > 0)
                "
              >
                <div
                  class="data_box"
                  v-if="
                    !!use_stat.erc20 && Object.keys(use_stat.erc20).length > 0
                  "
                >
                  <div
                    v-for="(item, key, index) in use_stat.erc20"
                    :key="index"
                  >
                    {{ item }}
                    <sub class="company">{{ key.toUpperCase() }}</sub>
                  </div>
                </div>
                <div
                  class="data_box nft"
                  v-if="
                    web == 'opensea' && Object.keys(use_stat.nft).length > 0
                  "
                >
                  <!-- nft资产 -->
                  <div class="type_title">NFT:</div>
                  <template
                    v-if="
                      !!use_stat.nft && Object.keys(use_stat.nft).length > 0
                    "
                  >
                    <div
                      v-for="(item, key, index) in use_stat.nft"
                      :key="index"
                    >
                      {{ item }}
                      <sub class="company">{{ key.toUpperCase() }}</sub>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item no_data no_data_nft">
                      {{ $t("common.noData") }}
                    </div>
                  </template>
                </div>
              </div>
              <div v-else>
                <div class="no_data">{{ $t("common.noData") }}</div>
              </div>
            </div>
          </van-swipe-item>

          <van-swipe-item>
            <div class="swipe_item">
              <div class="title">{{ $t("subManage.totalAssets") }}</div>
              <!-- NFT-移动 -->
              <div
                class="money"
                v-if="
                  (!!attached.erc20 || !!attached.nft) &&
                  (Object.keys(attached.erc20).length > 0 ||
                    Object.keys(attached.nft).length > 0)
                "
              >
                <div
                  class="data_box"
                  v-if="
                    !!attached.erc20 && Object.keys(attached.erc20).length > 0
                  "
                >
                  <div
                    v-for="(item, key, index) in attached.erc20"
                    :key="index"
                  >
                    {{ item }}
                    <sub class="company">{{ key.toUpperCase() }}</sub>
                  </div>
                </div>
                <div
                  class="data_box nft"
                  v-if="
                    web == 'opensea' && Object.keys(attached.nft).length > 0
                  "
                >
                  <!-- nft资产 -->
                  <div class="type_title">NFT:</div>
                  <template
                    v-if="
                      !!attached.nft && Object.keys(attached.nft).length > 0
                    "
                  >
                    <div
                      v-for="(item, key, index) in attached.nft"
                      :key="index"
                    >
                      {{ item }}
                      <sub class="company">{{ key.toUpperCase() }}</sub>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item no_data no_data_nft">
                      {{ $t("common.noData") }}
                    </div>
                  </template>
                </div>
              </div>
              <div v-else>
                <div class="no_data">{{ $t("common.noData") }}</div>
              </div>
            </div>
          </van-swipe-item>

          <van-swipe-item>
            <div class="swipe_item">
              <div class="title">{{ $t("subManage.notUseAssets") }}</div>
              <div
                class="money"
                v-if="
                  (!!not_use_stat.erc20 || !!not_use_stat.nft) &&
                  (Object.keys(not_use_stat.erc20).length > 0 ||
                    Object.keys(not_use_stat.nft).length > 0)
                "
              >
                <div
                  class="data_box"
                  v-if="
                    !!not_use_stat.erc20 &&
                    Object.keys(not_use_stat.erc20).length > 0
                  "
                >
                  <div
                    v-for="(item, key, index) in not_use_stat.erc20"
                    :key="index"
                  >
                    {{ item }}
                    <sub class="company">{{ key.toUpperCase() }}</sub>
                  </div>
                </div>
                <div
                  class="data_box nft"
                  v-if="
                    web == 'opensea' && Object.keys(not_use_stat.nft).length > 0
                  "
                >
                  <!-- nft资产 -->
                  <div class="type_title">NFT:</div>
                  <template
                    v-if="
                      !!not_use_stat.nft &&
                      Object.keys(not_use_stat.nft).length > 0
                    "
                  >
                    <div
                      v-for="(item, key, index) in not_use_stat.nft"
                      :key="index"
                    >
                      {{ item }}
                      <sub class="company">{{ key.toUpperCase() }}</sub>
                    </div>
                  </template>
                  <template v-else>
                    <div class="item no_data no_data_nft">
                      {{ $t("common.noData") }}
                    </div>
                  </template>
                </div>
              </div>
              <div v-else>
                <div class="no_data">{{ $t("common.noData") }}</div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
        <!-- dex工具tab -->
        <div class="tabList">
          <div
            :class="{ active: selectTool == 'wallet' }"
            @click="openTool('wallet')"
          >
            <div class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/wallet.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/wallet_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/wallet_active.png')"
              />
            </div>
            <div>{{ $t("tools.wallet.name") }}</div>
          </div>
          <!-- 导入交易钱包 -->
          <div
            :class="{ active: selectTool == 'importWallet' }"
            @click="openImport"
          >
            <div class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/import.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/import_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/import_active.png')"
              />
            </div>
            <div>{{ $t("tools.importWallet.name") }}</div>
          </div>
          <div
            :class="{ active: selectTool == 'distribution' }"
            @click="openTool('distribution')"
          >
            <div class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/distribution.png')"
              />
              <img
                v-else
                class="img"
                :src="
                  require('@/assets/images/subManage/distribution_light.png')
                "
              />
              <img
                class="img_active"
                :src="
                  require('@/assets/images/subManage/distribution_active.png')
                "
              />
            </div>
            <div>{{ $t("tools.distribution.name") }}</div>
          </div>
          <div
            :class="{ active: selectTool == 'pool' }"
            @click="openTool('pool')"
          >
            <div class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/pool.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/pool_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/pool_active.png')"
              />
            </div>
            <div>{{ $t("tools.pool.name") }}</div>
          </div>
          <div
            :class="{ active: selectTool == 'empower' }"
            @click="openTool('empower')"
          >
            <div class="icon icon2">
              <i class="iconfont2 icon-shouquan"></i>
            </div>
            <div>{{ $t("tools.empower.name2") }}</div>
          </div>
          <div :class="{ active: selectTool == 'refresh' }" @click="refresh">
            <div class="icon">
              <img
                v-if="theme != 'light'"
                class="img"
                :src="require('@/assets/images/subManage/refresh.png')"
              />
              <img
                v-else
                class="img"
                :src="require('@/assets/images/subManage/refresh_light.png')"
              />
              <img
                class="img_active"
                :src="require('@/assets/images/subManage/refresh_active.png')"
              />
            </div>
            <div>{{ $t("tools.refresh.name") }}</div>
          </div>
        </div>
        <!-- 资金钱包 -->
        <div class="main_wallet">
          <div class="head">{{ $t("subManage.mainWallet") }}</div>
          <div class="list">
            <div class="item" v-if="Object.keys(mainWallet).length > 0">
              <div class="row">
                <div class="label">{{ $t("subManage.address") }}</div>
                <div class="value">{{ mainWallet.address }}</div>
              </div>
              <div class="row">
                <div class="label">{{ $t("common.exchange") }}</div>
                <div class="value">{{ mainWallet.web_name }}</div>
              </div>
              <div class="row">
                <div class="label">{{ $t("subManage.chain") }}</div>
                <div class="value">{{ mainWallet.chain }}</div>
              </div>
              <div class="row">
                <div class="label">{{ $t("subManage.assets") }}</div>
                <div class="value" v-html="mainWallet.assets"></div>
              </div>
              <div class="row" v-if="web != 'opensea'">
                <div class="label">{{ $t("subManage.approveTokens") }}</div>
                <div class="value approve_list">
                  <template
                    v-if="
                      !mainWallet.approve_list ||
                      mainWallet.approve_list.length > 0
                    "
                  >
                    <div
                      v-for="(item, index) in mainWallet.approve_list"
                      :key="index"
                    >
                      {{ item }}
                    </div>
                  </template>
                  <template v-else> / </template>
                </div>
              </div>
              <!-- NFT-移动 -->
              <div class="row" v-if="web == 'opensea'">
                <div class="label">{{ $t("subManage.assets") }}(NFT)</div>
                <div class="value">
                  <template v-if="!!mainWallet.assets_nft">
                    <div v-html="mainWallet.assets_nft"></div>
                    <div
                      class="open_close"
                      v-if="boxNum.indexOf(mainWallet.address) < 0"
                    >
                      <span
                        class="btn"
                        @click="showDetail(mainWallet.id, mainWallet.address)"
                        >{{ $t("subManage.openBtn") }}</span
                      >
                    </div>
                  </template>
                  <template v-else> / </template>
                </div>
              </div>
              <div
                class="item_detail_box"
                :ref="mainWallet.address"
                v-if="web == 'opensea' && !!mainWallet.assets_nft"
              >
                <div class="item_detail">
                  <div
                    class="detail_item_box"
                    v-for="(nft, nft_index) in boxDataList[mainWallet.address]"
                    :key="nft_index"
                  >
                    <div class="img">
                      <el-image :src="nft.token_image" fit="contain">
                        <div slot="placeholder" class="image-slot">
                          {{ $t("common.loding") }}<span class="dot">...</span>
                        </div>
                      </el-image>
                      <div class="label">{{ nft.contract_type }}</div>
                    </div>
                    <!-- 如果没有图片 -->
                    <!-- <div class="img">
                                            <div class="value">{{nft.token_image}}</div>
                                        </div> -->
                    <div class="detail_row">
                      <span class="label"> token: </span>
                      <span class="value">
                        <el-tooltip
                          effect="dark"
                          :content="nft.token"
                          placement="top"
                        >
                          <div class="value">{{ nft.token }}</div>
                        </el-tooltip>
                      </span>
                    </div>
                    <div class="detail_row">
                      <span class="label"> token ID: </span>
                      <span class="value">
                        <el-tooltip
                          effect="dark"
                          :content="nft.token_id"
                          placement="top"
                        >
                          <div class="value">{{ nft.token_id }}</div>
                        </el-tooltip>
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  class="open_close"
                  v-if="boxNum.indexOf(mainWallet.address) >= 0"
                >
                  <span class="btn" @click="closeDetail(mainWallet.address)">{{
                    $t("subManage.closeBtn")
                  }}</span>
                </div>
              </div>
              <!-- 交易限制 -->
              <div class="row restriction">
                <div class="label">
                  {{ $t("subManage.transactionRestriction") }}
                </div>
                <div
                  class="value"
                  @click="
                    openRestriction(
                      mainWallet.id,
                      $t('subManage.mainWallet'),
                      mainWallet.trade_type,
                      'main'
                    )
                  "
                >
                  {{
                    mainWallet.trade_type == 1
                      ? $t("subManage.buyOnly")
                      : mainWallet.trade_type == 2
                      ? $t("subManage.sellOnly")
                      : $t("subManage.unlimited")
                  }}
                  <i class="iconfont2 icon-restriction"></i>
                </div>
              </div>
              <!-- 参与下单 -->
              <div class="row" v-if="web != 'opensea' || subWalletList.length">
                <div class="label">{{ $t("subManage.participate") }}</div>
                <div class="value red" v-if="mainWallet.state == '0'">
                  {{ $t("common.notEnabled") }}
                </div>
                <div class="value green" v-else-if="mainWallet.state == '1'">
                  {{ $t("common.enabled") }}
                </div>
              </div>

              <div class="btn_box">
                <div
                  class="g_btn btn"
                  :class="mainWallet.state == '1' ? 'red' : 'green'"
                  @click="switchChangePhone(mainWallet)"
                  v-if="web != 'opensea' || subWalletList.length"
                >
                  {{
                    mainWallet.state == "0"
                      ? $t("subManage.start")
                      : $t("subManage.stop")
                  }}
                </div>
                <div class="g_btn btn green" @click="mainRefresh">
                  {{ $t("common.refresh") }}
                </div>
                <div
                  class="g_btn btn red"
                  @click="openPopAssets(mainWallet, 'main')"
                >
                  {{ $t("subManage.transfer") }}
                </div>
              </div>
            </div>
            <template v-else>
              <div class="no_data">{{ $t("common.noData") }}</div>
            </template>
          </div>
        </div>
        <!-- 交易钱包列表 -->
        <div class="main_wallet sub_wallet">
          <div class="head">
            <div class="left">
              {{ $t("subManage.subWalletList") }}
              <span>
                {{
                  "(" +
                  (!source ? $t("common.enabled") : $t("subManage.enabled")) +
                  "：" +
                  enable +
                  "，" +
                  $t("common.total") +
                  "：" +
                  count +
                  ")"
                }}
              </span>
            </div>
            <!-- switchSort 排序 -->
            <div class="right" @click="switchSort">
              <i class="iconfont2 icon-qiehuan switch_icon"></i>
            </div>
          </div>
          <!-- 交易限制筛选及设定 -->
          <div class="search">
            <div class="checkbox">
              <el-tooltip
                effect="dark"
                :content="$t('subManage.limitTip')"
                placement="top-start"
              >
                <span
                  >{{ $t("subManage.transactionRestriction")
                  }}<i class="iconfont icon-tips"></i
                ></span>
              </el-tooltip>
              <el-radio-group
                v-model="listRestriction"
                @change="getSubWalletsListLoad"
              >
                <el-radio :label="-1">{{ $t("common.whole") }}</el-radio>
                <el-radio :label="0">{{ $t("subManage.unlimited") }}</el-radio>
                <el-radio :label="1">{{ $t("subManage.buyOnly") }}</el-radio>
                <el-radio :label="2">{{ $t("subManage.sellOnly") }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- 钱包来源筛选 -->
          <div class="search">
            <div class="checkbox">
              {{ $t("subManage.source") }}：
              <el-radio-group v-model="source" @change="getSubWalletsListLoad">
                <el-radio :label="0">{{ $t("common.whole") }}</el-radio>
                <el-radio :label="1">{{ $t("subManage.onlyCreate") }}</el-radio>
                <el-radio :label="2">{{ $t("subManage.onlyImport") }}</el-radio>
              </el-radio-group>
            </div>
          </div>
          <!-- 分组筛选 -->
          <div class="search">
            <div class="checkbox">
              {{ $t("subManage.groupFilter") }}：
              <el-checkbox-group
                v-model="groups"
                @change="getSubWalletsListLoad"
              >
                <el-checkbox
                  :label="item.id"
                  v-for="(item, index) in groupsList"
                  :key="index"
                >
                  {{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>

          <!-- 按钮 -->
          <div class="search">
            <div class="btn_box">
              <div
                class="g_btn btn"
                :class="{ g_btn_disable: selectList.length == 0 || !hasEnable }"
                @click="setAttachedAll(1, selectList.length == 0 || !hasEnable)"
              >
                {{ $t("subManage.allOpen") }}
              </div>
              <div
                class="g_btn btn"
                :class="{ g_btn_disable: selectList.length == 0 || !hasClose }"
                @click="setAttachedAll(0, selectList.length == 0 || !hasClose)"
              >
                {{ $t("subManage.allClose") }}
              </div>
              <div class="g_btn btn" @click="exportAdd">
                {{ $t("subManage.subWalletExport") }}
              </div>

              <!-- 添加分组 -->
              <div class="g_btn btn" @click="openAddGroups">
                {{ $t("subManage.addGroup") }}
              </div>
              <!-- 删除分组 -->
              <div
                class="g_btn btn"
                :class="{ g_btn_disable: groupsList.length <= 1 }"
                @click="openDelGroups(groupsList.length <= 1)"
              >
                {{ $t("subManage.delGroup") }}
              </div>
            </div>
          </div>

          <div class="list">
            <template v-if="subWalletList.length">
              <div
                class="list"
                v-infinite-scroll="load"
                :infinite-scroll-distance="10"
                :infinite-scroll-delay="400"
                :infinite-scroll-disabled="load_disabled"
              >
                <div
                  class="item"
                  v-for="(item, index) in subWalletList"
                  :key="index"
                >
                  <div class="row">
                    <div class="label">{{ $t("subManage.address") }}</div>
                    <div class="value">{{ item.address }}</div>
                  </div>
                  <div class="row" v-if="mainWallet.web_name">
                    <div class="label">{{ $t("common.exchange") }}</div>
                    <div class="value">{{ mainWallet.web_name }}</div>
                  </div>
                  <div class="row" v-if="mainWallet.chain">
                    <div class="label">{{ $t("subManage.chain") }}</div>
                    <div class="value">{{ mainWallet.chain }}</div>
                  </div>
                  <div class="row">
                    <div class="label">{{ $t("subManage.assets") }}</div>
                    <div class="value" v-html="item.assets"></div>
                  </div>
                  <div class="row" v-if="web != 'opensea'">
                    <div class="label">{{ $t("subManage.approveTokens") }}</div>
                    <div class="value approve_list">
                      <template
                        v-if="
                          !item.approve_list || item.approve_list.length > 0
                        "
                      >
                        <div
                          v-for="(it, index) in item.approve_list"
                          :key="index"
                        >
                          {{ it }}
                        </div>
                      </template>
                      <template v-else> / </template>
                    </div>
                  </div>
                  <!-- 交易限制 -->
                  <div class="row restriction">
                    <div class="label">
                      {{ $t("subManage.transactionRestriction") }}
                    </div>
                    <div
                      class="value"
                      @click="
                        openRestriction(
                          item.id,
                          item.address,
                          item.trade_type,
                          'sub'
                        )
                      "
                    >
                      {{
                        item.trade_type == 1
                          ? $t("subManage.buyOnly")
                          : item.trade_type == 2
                          ? $t("subManage.sellOnly")
                          : $t("subManage.unlimited")
                      }}
                      <i class="iconfont2 icon-restriction"></i>
                    </div>
                  </div>
                  <!-- 当前分组 -->
                  <div class="row restriction">
                    <div class="label">{{ $t("subManage.currentGroup") }}</div>
                    <div class="value">
                      {{ item.group_name.name }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="label">{{ $t("subManage.currentState") }}</div>
                    <div class="value red" v-if="item.state == '0'">
                      {{ $t("common.notEnabled") }}
                    </div>
                    <div class="value green" v-else-if="item.state == '1'">
                      {{ $t("common.enabled") }}
                    </div>
                  </div>
                  <!-- NFT-移动 -->
                  <div class="row" v-if="web == 'opensea'">
                    <div class="label">{{ $t("subManage.assets") }}(NFT)</div>
                    <div class="value">
                      <template v-if="!!item.assets_nft">
                        <div v-html="item.assets_nft"></div>

                        <div
                          class="open_close"
                          v-if="boxNum.indexOf(item.address) < 0"
                        >
                          <span
                            class="btn"
                            @click="showDetail(item.id, item.address, 'sub')"
                            >{{ $t("subManage.openBtn") }}</span
                          >
                        </div>
                      </template>
                      <template v-else> / </template>
                    </div>
                  </div>
                  <div
                    class="item_detail_box"
                    :ref="item.address"
                    v-if="web == 'opensea' && !!item.assets_nft"
                  >
                    <div class="item_detail">
                      <div
                        class="detail_item_box"
                        v-for="(nft, nft_index) in boxDataList[item.address]"
                        :key="nft_index"
                      >
                        <div class="img">
                          <el-image :src="nft.token_image" fit="contain">
                            <div slot="placeholder" class="image-slot">
                              {{ $t("common.loding")
                              }}<span class="dot">...</span>
                            </div>
                          </el-image>
                          <div class="label">{{ nft.contract_type }}</div>
                        </div>
                        <!-- 如果没有图片 -->
                        <!-- <div class="img">
                                                    <div class="value">{{nft.token_image}}</div>
                                                </div> -->
                        <div class="detail_row">
                          <span class="label"> token: </span>
                          <span class="value">
                            <el-tooltip
                              effect="dark"
                              :content="nft.token"
                              placement="top"
                            >
                              <div class="value">{{ nft.token }}</div>
                            </el-tooltip>
                          </span>
                        </div>
                        <div class="detail_row">
                          <span class="label"> token ID: </span>
                          <span class="value">
                            <el-tooltip
                              effect="dark"
                              :content="nft.token_id"
                              placement="top"
                            >
                              <div class="value">{{ nft.token_id }}</div>
                            </el-tooltip>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      class="open_close"
                      v-if="boxNum.indexOf(item.address) >= 0"
                    >
                      <span
                        class="btn"
                        @click="closeDetail(item.address, 'sub')"
                        >{{ $t("subManage.closeBtn") }}</span
                      >
                    </div>
                  </div>

                  <div class="btn_box">
                    <div
                      class="g_btn btn btn_half"
                      :class="item.state == '1' ? 'red' : 'green'"
                      @click="switchChangePhone(item)"
                    >
                      {{
                        item.state == "0"
                          ? $t("subManage.start")
                          : $t("subManage.stop")
                      }}
                    </div>
                    <div
                      class="g_btn btn btn_half red"
                      @click="openPopAssets(item, 'sub')"
                    >
                      {{ $t("subManage.transfer") }}
                    </div>

                    <!-- 替换分组 -->
                    <div
                      class="g_btn btn btn_half green"
                      @click="openMoveGroups(false, item)"
                    >
                      {{ $t("subManage.replaceGroup") }}
                    </div>
                    <!-- 删除 -->
                    <div
                      class="g_btn btn btn_half red"
                      @click="setAttachedAll(2, false, item)"
                    >
                      {{ $t("common.del") }}
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <el-empty v-else :image="emptyImg" :image-size="120" />
            <!-- 
                            <div class="enable">
                                <el-switch
                                @change=""
                                active-color="#E65B1B"
                                inactive-color="#e9e9e9"
                                active-value="1"
                                inactive-value="0"
                                v-model="item.state">
                                </el-switch>
                            </div>
                         -->
          </div>
        </div>
      </div>
    </template>

    <!-- 绑定邮箱 -->
    <emailBind ref="emailBind"></emailBind>
    <!-- 安全验证 -->
    <!-- <el-dialog :title="$t('subManage.safeVer')" :visible.sync="showEmailVer" :show-close="false" center
            :before-close="emailCancel">
            <div class="add_form">
                <div class="bind_form">
                    <div class="item">
                        <div class="title">{{$t('common.email')}}</div>
                        <div class="text">
                            {{email}}
                        </div>
                    </div>
                    <div class="item">
                        <div class="title">{{$t('common.verification')}}</div>
                        <div class="text">
                            <div class="inp_code">
                                <input class="inp" :placeholder="$t('common.inputVerification')" type="text"
                                    v-model="sms_code" />
                                <div v-if="codeSending" class="g_btn btn disabled">{{$t('common.countDown')}}{{
                                    timeCount }}s</div>
                                <div v-else class="g_btn btn" @click="handleSendCode">
                                    <template v-if="firstSend">{{$t('common.sendVerification')}}</template>
                                    <template v-else>{{$t('common.resend')}}</template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tip">{{$t('subManage.safeVerTip')}}</div>
                </div>
                <div class="foot">
                    <div class="btn" @click="emailCancel">{{$t('common.cancel')}}</div>
                    <div class="btn submit" @click="singleTransfer">{{$t('common.confirm2')}}</div>
                </div>
            </div>
        </el-dialog> -->

    <!-- form弹窗 -->
    <el-dialog
      :visible.sync="showTool"
      :show-close="false"
      center
      :before-close="beforeClose"
    >
      <div class="dialog_title" slot="title">
        <div>{{ selectTool ? $t("tools." + selectTool + ".name") : "" }}</div>
        <div class="help" @click="showHelp = true">
          <i class="iconfont icon-tips"></i>{{ $t("common.description") }}
        </div>
      </div>
      <div class="tabform">
        <!-- 创建交易钱包 -->
        <div v-if="selectTool == 'wallet'">
          <div class="item">
            <div class="title">{{ $t("stocks.chain") }}</div>
            <div class="inp_box">
              <el-select
                v-model="mainWallet.chain"
                :placeholder="$t('stocks.chain')"
                readonly
              >
                <el-option
                  v-for="(item, key, index) in chain_list"
                  :key="index"
                  :label="key"
                  :value="key"
                  :disabled="key != mainWallet.chain"
                >
                  <el-tooltip
                    :content="$t('common.exchange') + '：' + item.join('，')"
                    placement="top-end"
                    :enterable="false"
                  >
                    <div>{{ key }}</div>
                  </el-tooltip>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.wallets") }}</div>
            <div class="inp_box">
              <el-input
                v-model="toolform.wallets"
                @input.native="
                  inputHandlerMax($event, 'toolform', 'wallets', 200)
                "
                :placeholder="$t('common.max') + ' 200'"
                class="inp"
              />
            </div>
          </div>
          <!-- 交易限制 -->
          <div class="item">
            <div class="title">
              {{ $t("subManage.transactionRestriction") }}
            </div>
            <div class="inp_box">
              <el-select
                v-model="restriction"
                :placeholder="$t('common.select')"
              >
                <el-option
                  :label="$t('subManage.unlimited')"
                  :value="0"
                ></el-option>
                <el-option
                  :label="$t('subManage.buyOnly')"
                  :value="1"
                ></el-option>
                <el-option
                  :label="$t('subManage.sellOnly')"
                  :value="2"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.walletGroup") }}</div>
            <div class="inp_box">
              <el-select v-model="groupId" :placeholder="$t('common.select')">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in groupsList"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- 邮箱验证码 -->
          <div class="item">
            <div class="title">{{ $t("common.verification") }}</div>
            <div class="inp_box">
              <div class="inp_code">
                <el-input
                  :placeholder="$t('common.inputVerification')"
                  type="text"
                  v-model="toolform.sms_code"
                />
                <div v-if="codeSending" class="g_btn btn disabled">
                  {{ $t("common.countDown") }}{{ timeCount }}s
                </div>
                <div v-else class="g_btn btn" @click="handleSendCode">
                  <template v-if="firstSend">{{
                    $t("common.sendVerification")
                  }}</template>
                  <template v-else>{{ $t("common.resend") }}</template>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selectTool == 'wallet'" class="create_tip">
            {{ $t("subManage.createTip") }}
          </div>
          <div v-if="selectTool == 'wallet'" class="create_tip">
            {{ $t("subManage.createTip2") }}
          </div>
        </div>
        <!-- 分拨资金 -->
        <div v-else-if="selectTool == 'distribution'">
          <!-- 资金来源 -->
          <div class="item">
            <div class="title">{{ $t("subManage.fundsSource") }}</div>
            <div class="inp_box">
              {{ sourceAddress }}
            </div>
          </div>

          <div class="item">
            <div class="title">{{ $t("stocks.chain") }}</div>
            <div class="inp_box">
              <el-select
                v-model="mainWallet.chain"
                :placeholder="$t('stocks.chain')"
                readonly
              >
                <el-option
                  v-for="(item, key, index) in chain_list"
                  :key="index"
                  :label="key"
                  :value="key"
                  :disabled="key != mainWallet.chain"
                >
                  <el-tooltip
                    :content="$t('common.exchange') + '：' + item.join('，')"
                    placement="top-end"
                    :enterable="false"
                  >
                    <div>{{ key }}</div>
                  </el-tooltip>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.walletGroup") }}</div>
            <div class="inp_box">
              <el-select v-model="groupId" :placeholder="$t('common.select')">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in groupsListAll"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("common.currency") }}</div>
            <div class="inp_box">
              <el-select
                v-model="toolform.currency"
                :placeholder="$t('common.currency')"
              >
                <el-option
                  v-for="(item, key, index) in masterWalletList"
                  :key="index"
                  :label="key"
                  :value="key"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item num" v-if="toolform.currency">
            <div class="title"></div>
            <div class="inp_box">
              {{
                $t("common.balance") +
                ":" +
                (masterWalletList[toolform.currency]
                  ? masterWalletList[toolform.currency]
                  : 0)
              }}
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.totalAmount") }}</div>
            <div class="inp_box">
              <el-input
                v-model="toolform.totalQuantity"
                @input.native="
                  inputHandler($event, 'toolform', 'totalQuantity')
                "
                :placeholder="$t('common.value')"
                class="inp hasAll"
              />
              <div class="all g_btn" @click="getAllDistribution">
                {{ $t("common.whole") }}
              </div>
            </div>
          </div>
          <!-- 策略 -->
          <div class="item">
            <div class="title">{{ $t("subManage.distributionStrategy") }}</div>
            <div class="inp_box">
              <el-select
                v-model="toolform.strategy"
                :placeholder="$t('subManage.strategy')"
                @change="strategyChange"
              >
                <el-option
                  :label="$t('subManage.randomAllocate')"
                  :value="0"
                ></el-option>
                <el-option
                  :label="$t('subManage.averageAllocate')"
                  :value="1"
                ></el-option>
                <el-option
                  :label="$t('subManage.conditionalAllocate')"
                  :value="2"
                ></el-option>
              </el-select>
            </div>
          </div>
          <!-- 附加字段 -->
          <div class="item" v-if="toolform.strategy == 2">
            <div class="title">{{ $t("subManage.walletBelow") }}</div>
            <div class="inp_box">
              <el-input
                v-model="toolform.money"
                @input.native="inputHandler($event, 'toolform', 'money')"
                :placeholder="$t('common.value')"
                class="inp"
              />
              <span class="unit">{{
                toolform.currency ? toolform.currency.toUpperCase() : ""
              }}</span>
            </div>
          </div>
          <div
            class="item"
            v-if="toolform.strategy == 1 || toolform.strategy == 2"
          >
            <div class="title">{{ $t("subManage.floatRange") }}</div>
            <div class="inp_box" id="float">
              <el-input
                @input.native="
                  inputHandlerMax($event, 'toolform', 'float', 100)
                "
                v-model="toolform.float"
                :placeholder="$t('common.value')"
                class="inp"
              />
              <span class="unit">%</span>
            </div>
          </div>
          <div class="tip" v-if="is0">{{ $t("subManage.floatTip1") }}</div>
          <div class="tip" v-else-if="is80">
            {{ $t("subManage.floatTip2") }}
          </div>
        </div>
        <!-- 归集资金 -->
        <div v-else-if="selectTool == 'pool'">
          <div class="item">
            <div class="title">{{ $t("stocks.chain") }}</div>
            <div class="inp_box">
              <el-select
                v-model="mainWallet.chain"
                :placeholder="$t('stocks.chain')"
                readonly
              >
                <el-option
                  v-for="(item, key, index) in chain_list"
                  :key="index"
                  :label="key"
                  :value="key"
                  :disabled="key != mainWallet.chain"
                >
                  <el-tooltip
                    :content="$t('common.exchange') + '：' + item.join('，')"
                    placement="top-end"
                    :enterable="false"
                  >
                    <div>{{ key }}</div>
                  </el-tooltip>
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.walletGroup") }}</div>
            <div class="inp_box">
              <el-select v-model="groupId" :placeholder="$t('common.select')">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in groupsListAll"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("common.currency") }}</div>
            <div class="inp_box" v-if="groupId == -999">
              <el-select
                v-model="toolform.currency"
                :placeholder="$t('common.currency')"
              >
                <el-option
                  v-for="(item, key, index) in useStatList"
                  :key="index"
                  :label="key"
                  :value="key"
                >
                </el-option>
              </el-select>
            </div>
            <div class="inp_box" v-else>
              <el-select
                v-model="toolform.currency"
                :placeholder="$t('common.currency')"
              >
                <el-option
                  v-for="(item, key, index) in groupOpenStatList[groupId]"
                  :key="index"
                  :label="key"
                  :value="key"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="item num" v-if="toolform.currency">
            <div class="title"></div>
            <div class="inp_box">
              {{
                $t("common.balance") +
                ":" +
                (groupId == -999
                  ? useStatList[toolform.currency]
                  : groupOpenStatList[groupId][toolform.currency] || 0)
              }}
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.totalAmount") }}</div>
            <div class="inp_box">
              <el-input
                v-model="toolform.totalQuantity"
                @input.native="
                  inputHandler($event, 'toolform', 'totalQuantity')
                "
                :placeholder="$t('common.value')"
                class="inp hasAll"
              />
              <div class="all g_btn" @click="getAllPool">
                {{ $t("common.whole") }}
              </div>
            </div>
          </div>
        </div>
        <!-- 新币授权 -->
        <div v-else-if="selectTool == 'empower'">
          <div class="item">
            <div class="title">{{ $t("stocks.token") }}</div>
            <div class="inp_box">
              <el-input
                v-model="toolform.token"
                :placeholder="$t('stocks.tokenName')"
                class="inp biginp chain"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("subManage.walletGroup") }}</div>
            <div class="inp_box">
              <el-select v-model="groupId" :placeholder="$t('common.select')">
                <el-option
                  :label="item.name"
                  :value="item.id"
                  v-for="(item, index) in groupsListAll"
                  :key="index"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="btn_box">
          <div class="btn" @click="cancelClose">{{ $t("common.cancel") }}</div>
          <div class="btn submit" @click="submitTool">
            {{ $t("common.submit") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 导入交易钱包 -->
    <el-dialog
      :title="$t('tools.importWallet.name')"
      :visible.sync="showImport"
      :show-close="false"
      center
      :before-close="beforeClose"
    >
      <div class="form">
        <!-- 交易限制 -->
        <div class="row">
          <div class="title">
            {{ $t("subManage.transactionRestriction") }} :
          </div>
          <div class="inp_box">
            <el-select v-model="restriction" :placeholder="$t('common.select')">
              <el-option
                :label="$t('subManage.unlimited')"
                :value="0"
              ></el-option>
              <el-option
                :label="$t('subManage.buyOnly')"
                :value="1"
              ></el-option>
              <el-option
                :label="$t('subManage.sellOnly')"
                :value="2"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="row">
          <div class="title">{{ $t("subManage.walletGroup") }} :</div>
          <div class="inp_box">
            <el-select v-model="groupId" :placeholder="$t('common.select')">
              <el-option
                :label="item.name"
                :value="item.id"
                v-for="(item, index) in groupsList"
                :key="index"
              ></el-option>
            </el-select>
          </div>
        </div>
        <div class="textarea">
          <el-input
            spellcheck="false"
            type="textarea"
            :rows="10"
            :placeholder="$t('subManage.key')"
            v-model="addressValue"
          >
          </el-input>
        </div>
        <div class="tip">
          {{ $t("subManage.currently") + len + $t("subManage.entry") }}
        </div>
        <div class="tip import_tip">{{ $t("subManage.importTip") }}</div>
        <div class="tip import_tip">{{ $t("subManage.importTip2") }}</div>
        <div class="btn_box">
          <div class="btn" @click="importClose">{{ $t("common.cancel") }}</div>
          <div class="btn submit" @click="importNext">
            {{ $t("common.next") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 导入交易钱包 step2 邮箱验证 -->
    <el-dialog
      :title="$t('tools.importWallet.name')"
      :visible.sync="showImportEmail"
      :show-close="false"
      center
      :before-close="beforeClose2"
    >
      <div class="form">
        <div class="code_line">
          <div class="title">{{ $t("common.email") }}</div>

          <div class="inp_box">
            {{ email }}
          </div>
        </div>
        <!-- 邮箱验证码 -->
        <div class="code_line">
          <div class="title">{{ $t("common.verification") }}</div>
          <div class="inp_box">
            <div class="inp_code">
              <el-input
                :placeholder="$t('common.inputVerification')"
                type="text"
                v-model="toolform.sms_code"
              />
              <div v-if="codeSending" class="g_btn btn disabled">
                {{ $t("common.countDown") }}{{ timeCount }}s
              </div>
              <div v-else class="g_btn btn" @click="handleSendCode">
                <template v-if="firstSend">{{
                  $t("common.sendVerification")
                }}</template>
                <template v-else>{{ $t("common.resend") }}</template>
              </div>
            </div>
          </div>
        </div>
        <div class="btn_box">
          <div class="btn" @click="importBack">{{ $t("common.pre") }}</div>
          <div class="btn submit" @click="submitImport">
            {{ $t("common.submit") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 功能说明弹窗 -->
    <el-dialog
      class="w900"
      :title="
        selectTool
          ? $t('tools.' + selectTool + '.name') + $t('common.explain')
          : ''
      "
      center
      :visible.sync="showHelp"
      :show-close="false"
    >
      <div
        class="tips_form"
        v-html="selectTool ? $t('tools.' + selectTool + '.html') : ''"
      ></div>
    </el-dialog>

    <!-- 资金划转 -->
    <el-dialog
      :title="$t('subManage.transfer')"
      :visible.sync="showTransfer"
      :show-close="false"
      center
      :before-close="beforeCloseTransfer"
    >
      <div class="transfer_form">
        <div>
          <!-- 单钱包划转/钱包分拨 -->
          <ul v-if="transferType != 'main'">
            <li :class="{ active: tabIndex == 0 }" @click="tabIndex = 0">
              {{ $t("subManage.single") }}
            </li>
            <li :class="{ active: tabIndex == 1 }" @click="tabIndex = 1">
              {{ $t("subManage.multiple") }}
            </li>
          </ul>
          <!-- 资金来源 -->
          <div class="item">
            <div class="title">{{ $t("subManage.fundsSource") }}</div>
            <div class="inp_box" :class="{ g_word_break: isMobile == 1 }">
              {{
                transferType == "main"
                  ? $t("subManage.mainWallet")
                  : sourceAddress
              }}
            </div>
          </div>
          <!-- 单钱包划转 -->
          <template v-if="tabIndex == 0">
            <div class="item">
              <div class="title">{{ $t("common.targetAddress") }}</div>
              <div class="inp_box">
                <el-input
                  type="textarea"
                  :rows="2"
                  :placeholder="$t('common.input')"
                  class="inp stretchNone"
                  v-model="transfer_form.address"
                >
                </el-input>
              </div>
            </div>
            <div
              class="item num"
              v-if="
                transfer_form.address && sourceAddress == transfer_form.address
              "
            >
              <div class="title"></div>
              <div class="inp_box">
                *{{ $t("subManage.targetAddressSame") }}
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $t("common.currency") }}</div>
              <div class="inp_box">
                <el-select
                  v-model="transfer_form.token"
                  :placeholder="$t('common.select')"
                >
                  <el-option
                    v-for="(item, key, index) in attachedList"
                    :key="index"
                    :label="key"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="item num" v-if="transfer_form.token">
              <div class="title"></div>
              <div class="inp_box">
                {{
                  $t("common.balance") + ": " + assetsObj[transfer_form.token]
                }}
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $t("common.num") }}</div>
              <div class="inp_box">
                <el-input
                  v-model="transfer_form.num"
                  @input.native="inputHandler($event, 'transfer_form', 'num')"
                  :placeholder="$t('common.value')"
                  class="inp hasAll"
                />
                <div class="all g_btn" @click="getAll">
                  {{ $t("common.whole") }}
                </div>
              </div>
            </div>
            <div class="tip transfer_tip">
              {{ $t("subManage.transferTip") }}
            </div>
          </template>
          <!-- 钱包分拨 -->
          <template v-else>
            <div class="item">
              <div class="title">{{ $t("stocks.chain") }}</div>
              <div class="inp_box">
                <el-select
                  v-model="mainWallet.chain"
                  :placeholder="$t('stocks.chain')"
                  readonly
                >
                  <el-option
                    v-for="(item, key, index) in chain_list"
                    :key="index"
                    :label="key"
                    :value="key"
                    :disabled="key != mainWallet.chain"
                  >
                    <el-tooltip
                      :content="$t('common.exchange') + '：' + item.join('，')"
                      placement="top-end"
                      :enterable="false"
                    >
                      <div>{{ key }}</div>
                    </el-tooltip>
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $t("common.currency") }}</div>
              <div class="inp_box">
                <el-select
                  v-model="transfer_form.token"
                  :placeholder="$t('common.select')"
                >
                  <el-option
                    v-for="(item, key, index) in attachedList"
                    :key="index"
                    :label="key"
                    :value="key"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="item num" v-if="transfer_form.token">
              <div class="title"></div>
              <div class="inp_box">
                {{
                  $t("common.balance") +
                  ": " +
                  attachedList[transfer_form.token]
                }}
              </div>
            </div>
            <div class="item">
              <div class="title">{{ $t("subManage.totalAmount") }}</div>
              <div class="inp_box">
                <el-input
                  v-model="transfer_form.num"
                  @input.native="inputHandler($event, 'transfer_form', 'num')"
                  :placeholder="$t('common.value')"
                  class="inp hasAll"
                />
                <div class="all g_btn" @click="getAll">
                  {{ $t("common.whole") }}
                </div>
              </div>
            </div>
            <!-- 策略 -->
            <div class="item">
              <div class="title">
                {{ $t("subManage.distributionStrategy") }}
              </div>
              <div class="inp_box">
                <el-select
                  v-model="transfer_form.strategy"
                  :placeholder="$t('common.select')"
                  @change="strategyChange2"
                >
                  <el-option
                    :label="$t('subManage.randomAllocate')"
                    :value="0"
                  ></el-option>
                  <el-option
                    :label="$t('subManage.averageAllocate')"
                    :value="1"
                  ></el-option>
                  <el-option
                    :label="$t('subManage.conditionalAllocate')"
                    :value="2"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <!-- 附加字段 -->
            <div class="item" v-if="transfer_form.strategy == 2">
              <div class="title">{{ $t("subManage.walletBelow") }}</div>
              <div class="inp_box">
                <el-input
                  v-model="transfer_form.money"
                  @input.native="inputHandler($event, 'transfer_form', 'money')"
                  :placeholder="$t('common.value')"
                  class="inp"
                />
                <span class="unit">{{
                  transfer_form.token ? transfer_form.token.toUpperCase() : ""
                }}</span>
              </div>
            </div>
            <div
              class="item"
              v-if="transfer_form.strategy == 1 || transfer_form.strategy == 2"
            >
              <div class="title">{{ $t("subManage.floatRange") }}</div>
              <div class="inp_box" id="float2">
                <el-input
                  @input.native="
                    inputHandlerMax($event, 'transfer_form', 'float', 100)
                  "
                  v-model="transfer_form.float"
                  :placeholder="$t('common.value')"
                  class="inp"
                />
                <span class="unit">%</span>
              </div>
            </div>
            <div class="tip" v-if="is0_transfer">
              {{ $t("subManage.floatTip1") }}
            </div>
            <div class="tip" v-else-if="is80_transfer">
              {{ $t("subManage.floatTip2") }}
            </div>
          </template>
        </div>
        <div class="btn_box">
          <div class="btn" @click="showTransfer = false">
            {{ $t("common.cancel") }}
          </div>
          <div class="btn submit" @click="submitTransfer">
            {{ $t("common.submit") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 资金钱包-交易限制弹窗 -->
    <el-dialog
      :title="$t('subManage.transactionRestrictionModify')"
      :visible.sync="showRestriction"
      :show-close="false"
      center
      :before-close="beforeCloseRestriction"
    >
      <div class="transfer_form">
        <div>
          <div class="item">
            <div class="title">{{ $t("common.tradingAddress") }}</div>
            <div class="inp_box" :class="{ g_word_break: isMobile == 1 }">
              {{ sourceAddress }}
            </div>
          </div>
          <div class="item">
            <div class="title">
              {{ $t("subManage.transactionRestriction") }}
            </div>
            <div class="inp_box">
              <el-select
                v-model="restriction"
                :placeholder="$t('common.select')"
              >
                <el-option
                  :label="$t('subManage.unlimited')"
                  :value="0"
                ></el-option>
                <el-option
                  :label="$t('subManage.buyOnly')"
                  :value="1"
                ></el-option>
                <el-option
                  :label="$t('subManage.sellOnly')"
                  :value="2"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="btn_box">
          <div class="btn" @click="showRestriction = false">
            {{ $t("common.cancel") }}
          </div>
          <div
            class="btn submit"
            :class="{ g_btn_disable: isIng }"
            @click="submitRestriction"
          >
            {{ $t("common.modify") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 地址导出 -->
    <el-dialog
      :title="$t('subManage.subWalletList')"
      :visible.sync="showExport"
      :show-close="false"
      center
      :before-close="beforeClose"
    >
      <div class="form">
        <div class="textarea">
          <el-input
            spellcheck="false"
            type="textarea"
            :rows="10"
            :placeholder="$t('subManage.key')"
            readonly
            v-model="addressValue"
          >
          </el-input>
        </div>
        <div class="tip">{{ $t("subManage.exportTotal", { num: len }) }}</div>
        <div class="tip import_tip">{{ $t("subManage.exportTip") }}</div>
        <div class="btn_box">
          <div class="btn" @click="exportClose">{{ $t("common.cancel") }}</div>
          <div class="btn submit copyBtn" @click="handleCopy(addressValue)">
            {{ $t("common.copy") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 新增分组 -->
    <el-dialog
      :title="$t('subManage.addGroup')"
      :visible.sync="showAddGroups"
      :show-close="false"
      center
      :before-close="beforeGroupsClose"
    >
      <div class="form">
        <div class="row">
          <el-input
            v-model="groupName"
            :placeholder="$t('subManage.groupNamePH')"
            class="inp"
          />
        </div>
        <div class="btn_box">
          <div class="btn" @click="showAddGroups = false">
            {{ $t("common.cancel") }}
          </div>
          <div class="btn submit" @click="handleAddGroups">
            {{ $t("common.confirm") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 删除分组 -->
    <el-dialog
      :title="$t('subManage.delGroup')"
      :visible.sync="showDelGroups"
      :show-close="false"
      center
      :before-close="beforeGroupsClose"
    >
      <div class="form">
        <div class="row">
          <el-checkbox-group v-model="delGroupList">
            <template v-for="(item, index) in groupsList">
              <el-checkbox :label="item.id" :key="index" v-if="item.id != 0">{{
                item.name
              }}</el-checkbox>
            </template>
          </el-checkbox-group>
        </div>
        <div class="btn_box">
          <div class="btn" @click="showDelGroups = false">
            {{ $t("common.cancel") }}
          </div>
          <div class="btn submit" @click="handleDelGroups">
            {{ $t("common.confirm") }}
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 替换分组 -->
    <el-dialog
      :title="$t('subManage.replaceGroup')"
      :visible.sync="showMoveGroups"
      :show-close="false"
      center
      :before-close="beforeGroupsClose"
    >
      <div class="form">
        <div class="row">
          <span class="title">{{ $t("subManage.currentGroup") }}: </span>
          <span
            class="tag"
            v-for="(item, index) in currentGroupList"
            :key="index"
            >{{ item }}</span
          >
        </div>
        <div class="row radioType">
          <el-radio-group v-model="moveToGroup">
            <el-radio
              :label="item.id"
              v-for="(item, index) in groupsList"
              :key="index"
              >{{ item.name }}
            </el-radio>
          </el-radio-group>
        </div>
        <div class="btn_box">
          <div class="btn" @click="showMoveGroups = false">
            {{ $t("common.cancel") }}
          </div>
          <div class="btn submit" @click="handleMoveGroups">
            {{ $t("common.confirm") }}
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 进度通知 -->
    <transition-group name="notification" tag="div">
      <template v-for="(item, key, index) in notifyProgressList">
        <div
          v-if="item.total>0"
          class="g_notification"
          :key="key"
          :style="{ top: calculateTop(index) + 'px' }"
          :ref="'notification-' + index"
        >
          <h2 class="notification__title">
            {{
              item.status == 2
                ? $t("subManage.completed")
                : $t(
                    "subManage." +
                      (item.type == "distribution" ? "allocating" : "collecting")
                  )
            }}
            <span class="g_btn" @click="closeNotify(item, key)">
              <i class="el-icon-close"></i>
            </span>
          </h2>
          <div class="notification__content">
            <div class="notifyTitle">
              <span
                >{{ $t("subManage.currentProgress") }} {{ item.progress }}/{{
                  item.total
                }}</span
              >
              <span class="g_btn btn" @click="jumpLog">{{
                $t("common.viewLog")
              }}</span>
            </div>

            <div class="notifyTitle notifyTitle2" v-if="item.use>0">
              <div class="circle use"></div>
              <span>{{ $t("subManage.completed") }} {{ item.use }}</span>
            </div>
            <div class="notifyTitle notifyTitle2" v-if="item.skip>0">
              <div class="circle skip"></div>
              <span>{{ $t("subManage.skip") }} {{ item.skip }}</span>
            </div>
            <div class="notifyTitle notifyTitle2" v-if="item.fail>0">
              <div class="circle fail"></div>
              <span
                >{{
                  $t(
                    "subManage." +
                      (item.type == "distribution"
                        ? "allocatFailed"
                        : "collectFailed")
                  )
                }}
                {{ item.fail }}</span
              >
            </div>

            <div class="notifyProgress">
              <div v-if="item.fail>0"
                class="fail"
                :style="{ width: (item.progress / item.total) * 100 + '%' }"
              ></div>
              <div v-if="item.skip>0"
                class="skip"
                :style="{ width: ((item.use+item.skip) / item.total) * 100 + '%' }"
              ></div>
              <div v-if="item.use>0"
                class="use"
                :style="{ width: (item.use / item.total) * 100 + '%' }"
              ></div>
            </div>
          </div>
        </div>
    </template>
    </transition-group>
  </div>
</template>

<style scoped="scoped" lang="stylus">
@import 'subManage.styl';
</style>

<script>
var timer;

import Clipboard from "clipboard";
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
import emailBind from "@/components/emailBind.vue";

import addressFormat from "@/utils/methApi/addressFormat";

// api
import {
  getChain,
  getWallets,
  getSubWalletsList, //获取交易钱包列表
  createDexWallets,
  dexDeliverToken,
  dexCollectToken,
  attachedWalletBalanceUpdate,
  batchImport,
  mainWalletBalanceUpdate,
  getWalletsList, //交易钱包地址导出
  dexWalletsApprove, //授权
  dexWalletStatWeb,
  subTransfer,
  setAttached,
  setAttachedAll,
  getNFTList,

  // 分组
  transferWalletGroup,
  createWalletGroup,
  delWalletGroup,
  getGroups,
  getScheduleData,
} from "@/api/markets";
import { sendEmail } from "@/api/user";
export default {
  components: {
    emailBind,
  },
  data() {
    return {
      emptyImg: require("@/assets/images/norecord.png"),
      attached: {},
      use_stat: {},
      not_use_stat: {},
      mainWallet: {},
      subWalletList: [],
      // 传递参数
      exchange: "",
      web: "",
      yenUnit1: "",
      yenUnit2: "",
      // 工具弹窗
      showTool: false,
      selectTool: "",
      chain_list: {}, //链列表
      toolform: {}, //量化工具数据
      // 导入交易钱包
      showImport: false,
      addressValue: "",
      // 分拨资金
      masterWalletList: {}, //币种下拉列表
      tabIndex: 0, //单钱包划转、钱包分拨
      // 归集资金
      useStatList: {}, //币种下拉列表
      groupOpenStatList: {},
      // 说明
      showHelp: false,
      // 资金划转
      showTransfer: false,
      transfer_form: {},
      attachedList: {}, //币种下拉列表
      assetsObj: {}, //当前row的资金
      transferType: "main", //钱包类型 main-资金钱包 sub-交易钱包
      sourceAddress: "", //资金来源地址
      // 交易钱包数量
      count: 0,
      enable: 0,
      // 展示钱包 交易钱包启用资产1/交易钱包总资产2/交易钱包未启用资产3
      selectNum: 2,
      // 交易钱包排序（默认0/启用优先1/停用优先2）
      sortState: 1,
      // 交易限制
      restriction: 0, //创建导入交易钱包-交易限制 0-无限制 1-只买 2-只卖
      showRestriction: false, //资金钱包-交易限制弹窗显示隐藏
      restrictionId: "", //资金钱包交易限制弹窗数据
      listRestriction: null, //交易钱包列表-交易限制筛选 -1-全部 0-无限制 1-只买 2-只卖

      // 分组
      groupId: "", //选择分组-创建、导入、分拨、归集、授权
      groups: [], // 筛选钱包的分组-ids
      groupsList: [], // 钱包分组列表
      groupsListAll: [], // 钱包分组列表含全部
      showAddGroups: false, // 添加分组弹窗
      groupName: "", // 添加分组名称
      showDelGroups: false, // 删除分组弹窗
      delGroupList: [], // 选中删除的分组
      showMoveGroups: false, // 替换分组弹窗
      currentGroupList: [], // 当前选中地址的分组列表
      moveToGroup: "", // 选中移动到的分组

      selectList: [], //交易钱包选中集合
      isIng: false,
      hasEnable: false, //有开启按钮
      hasClose: false, //有关闭按钮
      hasLimit: false, //有无限制按钮
      hasBuyOnly: false, //有只买按钮
      hasSellOnly: false, //有只卖按钮

      // NFT资产-展开的钱包集合
      boxNum: [
        // address
      ],
      // NFT资产-展开的钱包对应资产详情列表
      boxDataList: {
        // address:[
        //     {
        //         token:'',
        //         token_id:'',
        //         token_url:'',
        //     }
        // ]
      },

      // 邮箱验证
      codeSending: false,
      timeCount: 60,
      firstSend: true,
      // 导入交易钱包 邮箱验证弹窗
      showImportEmail: false,
      // 资金划转-安全验证-邮箱验证
      // showEmailVer: false,
      // sms_code: '',

      source: null,

      // 分页/无限滚动
      page: 1,
      pageNum: Number(localStorage.getItem("walletPageNum")) || 20,
      load_disabled: false,

      // 导出的交易钱包
      showExport: false,

      notifyProgressList: {},
      loadingServiceCode:'',
      loadingService:'',
    };
  },
  computed: {
    ...mapGetters(["account", "theme", "isMobile", "email"]),
    len() {
      var arr = this.addressValue.split("\n");
      var length = arr.length;
      if (!!arr[length - 1]) {
        return length;
      } else {
        return length - 1;
      }
    },
    is0() {
      return (
        (this.toolform.strategy == 1 || this.toolform.strategy == 2) &&
        (!this.toolform.float || this.toolform.float == 0)
      );
    },
    is80() {
      return (
        (this.toolform.strategy == 1 || this.toolform.strategy == 2) &&
        this.toolform.float >= 80
      );
    },
    is0_transfer() {
      return (
        (this.transfer_form.strategy == 1 ||
          this.transfer_form.strategy == 2) &&
        (!this.transfer_form.float || this.transfer_form.float == 0)
      );
    },
    is80_transfer() {
      return (
        (this.transfer_form.strategy == 1 ||
          this.transfer_form.strategy == 2) &&
        this.transfer_form.float >= 80
      );
    },
  },
  created() {
    // console.log(this.$route.query)
    var query = this.$route.query;
    this.exchange = query.exchange;
    this.web = query.web;
    this.yenUnit1 = query.yenUnit1;
    this.yenUnit2 = query.yenUnit2;
    if (query.openDistribution == "1") {
      this.openTool("distribution");
      this.toolform.currency = query.gas_token;
    }

    const loading = Loading.service({
      target: ".main",
      text: "Loading",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 0.7)",
      customClass: "apploading",
    });
    this.getGroupsList();
    Promise.all([this.getWallets(), this.getSubWalletsList()])
      .then(() => {
        // 进入钱包管理页时 如果没有交易钱包 则弹出创建交易钱包
        if (this.subWalletList.length == 0) {
          this.openTool("wallet");
        }
        loading.close();
      })
      .catch((err) => {
        console.log(err);
        loading.close();
      });
    // 获取链列表
    getChain({ web: this.web }).then((res) => {
      this.chain_list = res.chain_list;
    });
  },
  methods: {
    // 通知距顶高度计算
    calculateTop(index) {
      let top = 70; // 初始 top 值
      for (let i = 0; i < index; i++) {
        const notificationHeight =
          this.$refs[`notification-${i}`][0].offsetHeight;
        top += notificationHeight + 20; // 高度加上间距
      }
      return top;
    },
    // 正数
    inputHandler(event, objectName, position) {
      let value = event.target.value.replace(/[^0-9.]/g, "");
      // 避免中文输入法影响
      setTimeout(() => {
        this.$set(this[objectName], position, value >= 0 ? value : "");
      });
    },
    // 正整数 最大limit 200/100
    inputHandlerMax(event, objectName, position, limit) {
      let value = event.target.value.replace(/^(0+)|[^\d]+/g, "");
      value = value > limit ? limit : value;
      // 避免中文输入法影响
      setTimeout(() => {
        this.$set(this[objectName], position, value >= 0 ? Number(value) : "");
      });
    },

    // 交易钱包地址导出
    exportAdd() {
      this.selectTool = "export";
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      getWalletsList({
        account: this.account,
        web: this.web,
      })
        .then((res) => {
          loading.close();
          this.addressValue = res;
          this.showExport = true;
        })
        .catch((err) => {
          loading.close();
        });
    },
    exportClose() {
      this.selectTool = "";
      this.addressValue = "";
      this.showExport = false;
    },

    // 打开钱包交易限制弹窗-PC(资金钱包)、移动端（资金钱包、交易钱包）
    openRestriction(id, address, trade_type, wallet_type) {
      this.transferType = wallet_type;
      this.restrictionId = id;
      this.sourceAddress = address; //交易限制-默认钱包地址-资金钱包
      this.restriction = trade_type ? Number(trade_type) : 0;
      this.showRestriction = true;
    },
    // 关闭资金钱包交易限制弹窗
    beforeCloseRestriction(done) {
      // 初始化
      this.restrictionId = "";
      this.sourceAddress = "";
      this.restriction = 0;
      done();
    },
    // 提交资金钱包交易限制修改
    submitRestriction() {
      if (!this.isIng) {
        this.isIng = true;
        const loading = Loading.service({
          target: ".main",
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
          customClass: "apploading",
        });

        setAttached({
          account: this.account,
          wallet_id: this.restrictionId,
          type: 2,
          state: this.restriction || 0,
        })
          .then((res) => {
            this.$message({
              message: this.$t("tip.operationSuc"),
              type: "success",
              center: true,
              customClass: "shotMsg",
            });
            if (this.transferType == "main") {
              // this.getWallets()
              this.mainWallet.trade_type = this.restriction;
            } else {
              this.getSubWalletsListLoad();
            }
            this.showRestriction = false;
            loading.close();
            this.isIng = false;
          })
          .catch((err) => {
            loading.close();
            this.isIng = false;
          });
      }
    },

    // 表格多选-选中项改变
    handleSelectionChange(arr) {
      this.selectList = arr;

      var hasLimit = false,
        hasBuyOnly = false,
        hasSellOnly = false;
      var hasEnable = false,
        hasClose = false;

      arr.forEach((item) => {
        if (item.state == 1) {
          hasClose = true;
        } else {
          hasEnable = true;
        }

        if (item.trade_type == 1) {
          //只买
          hasLimit = true;
          hasSellOnly = true;
        } else if (item.trade_type == 2) {
          //只卖
          hasLimit = true;
          hasBuyOnly = true;
        } else {
          //无限制
          hasBuyOnly = true;
          hasSellOnly = true;
        }
      });
      this.hasEnable = hasEnable;
      this.hasClose = hasClose;

      this.hasLimit = hasLimit;
      this.hasBuyOnly = hasBuyOnly;
      this.hasSellOnly = hasSellOnly;
    },
    // 分页-数量改变
    handleSizeChange(val) {
      this.pageNum = val;
      localStorage.setItem("walletPageNum", val);
      this.handleCurrentChange(1);
    },
    // 分页-页码改变
    handleCurrentChange(page) {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });

      this.page = page;
      // console.log(`当前页: ${page}`);
      // 获取对应页交易钱包列表，切换列表
      this.getSubWalletsList()
        .then(() => {
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    // 加载更多
    load() {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });

      this.page++;
      // 获取对应页交易钱包列表，push列表
      this.getSubWalletsList("push")
        .then(() => {
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },

    // 钱包地址格式化
    addressFormat(address) {
      return addressFormat(address);
    },

    // 批量开启/关闭/删除 - 交易钱包
    setAttachedAll(num, isDisable, item) {
      if (!isDisable) {
        this.$confirm(
          num == 0
            ? this.$t("subManage.allCloseTip")
            : num == 1
            ? this.$t("subManage.allOpenTip")
            : num == 2
            ? this.$t("subManage.selectDeleteTip")
            : "",
          this.$t("tip.tips"),
          {
            confirmButtonText: this.$t("common.confirm"),
            center: true,
            showClose: false,
            customClass: "deleteOk",
            cancelButtonText: this.$t("common.back"),
          }
        ).then((res) => {
          const loading = Loading.service({
            target: ".main",
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "apploading",
          });
          let idList = [];
          if (this.isMobile == 0) {
            this.selectList.forEach((item) => {
              idList.push(item.id);
            });
          } else {
            idList = [item.id];
          }

          let data = {
            account: this.account,
            source: this.source || 0,
            wallet_ids: idList,
            state: num,
            web: this.web,
          };

          setAttachedAll(data)
            .then((res) => {
              this.$message({
                message: this.$t("tip.operationSuc"),
                type: "success",
                center: true,
                customClass: "shotMsg",
              });

              Promise.all([this.getWallets(), this.getSubWalletsList()])
                .then(() => {
                  loading.close();
                })
                .catch(() => {
                  loading.close();
                });
            })
            .catch((err) => {
              loading.close();
            });
        });
      }
    },
    // 批量设置选中项的交易限制 - 交易钱包
    setRestriction(state, isDisable) {
      if (!isDisable) {
        this.$confirm(
          state == 0
            ? this.$t("subManage.allUnlimitedTip")
            : state == 1
            ? this.$t("subManage.allBuyOnlyTip")
            : this.$t("subManage.allSellOnlyTip"),
          this.$t("tip.tips"),
          {
            confirmButtonText: this.$t("common.confirm"),
            center: true,
            showClose: false,
            customClass: "deleteOk",
            cancelButtonText: this.$t("common.back"),
          }
        ).then((res) => {
          const loading = Loading.service({
            target: ".main",
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "apploading",
          });

          var idList = [];
          this.selectList.forEach((item) => {
            idList.push(item.id);
          });
          var idStr = idList.join(",");
          setAttached({
            account: this.account,
            wallet_id: idStr,
            type: 2,
            state,
          })
            .then((res) => {
              this.$message({
                message: this.$t("tip.operationSuc"),
                type: "success",
                center: true,
                customClass: "shotMsg",
              });

              this.getSubWalletsList();
              loading.close();
            })
            .catch((err) => {
              loading.close();
            });
        });
      }
    },

    // 打开新增分组弹窗
    openAddGroups() {
      this.groupName = "";
      this.showAddGroups = true;
    },
    // 新增分组
    handleAddGroups() {
      if (!this.groupName.trim()) {
        this.$message({
          message: this.$t("subManage.groupNamePH"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
        return;
      } else if (this.groupName.length > 40) {
        this.$message({
          message: this.$t("subManage.groupNameErr"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
        return;
      }

      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      createWalletGroup({
        account: this.account,
        chain: this.mainWallet.chain,
        web: this.web,
        name: this.groupName,
      })
        .then((res) => {
          this.showAddGroups = false;
          this.getSubWalletsListLoad();
          this.getGroupsList();
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 打开删除分组弹窗
    openDelGroups(isDisable) {
      if (isDisable) return;
      this.delGroupList = [];
      this.showDelGroups = true;
    },
    // 删除分组
    handleDelGroups() {
      if (this.delGroupList.length == 0) {
        this.$message({
          message: this.$t("subManage.delGroupErr"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
        return;
      }

      this.$confirm(this.$t("subManage.delGroupTip"), this.$t("tip.tips"), {
        confirmButtonText: this.$t("common.confirm2"),
        cancelButtonText: this.$t("common.back"),
        center: true,
        showClose: false,
        customClass: "deleteOk",
        dangerouslyUseHTMLString: true,
      })
        .then(() => {
          const loading = Loading.service({
            target: ".main",
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "apploading",
          });
          delWalletGroup({
            account: this.account,
            chain: this.mainWallet.chain,
            web: this.web,
            group_ids: this.delGroupList.join(","),
          })
            .then((res) => {
              this.showDelGroups = false;
              this.getSubWalletsListLoad();
              this.getGroupsList();
              loading.close();
            })
            .catch((err) => {
              loading.close();
            });
        })
        .catch(() => {
          // 取消
        });
    },
    // 打开替换分组弹窗
    openMoveGroups(isDisable, item) {
      if (!isDisable) {
        // console.log(this.selectList)
        if (this.isMobile == 0) {
          this.currentGroupList = [
            ...new Set(this.selectList.map((item) => item.group_name.name)),
          ];
        } else {
          this.selectList = [JSON.parse(JSON.stringify(item))];
          this.currentGroupList = [item.group_name.name];
        }
        this.moveToGroup = "";
        this.showMoveGroups = true;
      }
    },
    // 移动分组
    handleMoveGroups() {
      var dexWalletIdsList = this.selectList
        .filter((item) => item.group_name.id !== this.moveToGroup)
        .map((item) => item.id);

      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      transferWalletGroup({
        account: this.account,
        chain: this.mainWallet.chain,
        web: this.web,
        group_id: this.moveToGroup,
        dex_wallet_ids: dexWalletIdsList.join(","),
      })
        .then((res) => {
          this.showMoveGroups = false;
          this.getSubWalletsListLoad();
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 关闭分组相关弹窗
    beforeGroupsClose(done) {
      this.groupName = "";

      this.delGroupList = [];

      this.moveToGroup = "";
      this.currentGroupList = [];
      done();
    },

    // emailCancel() {
    //     // 安全验证
    //     this.showEmailVer = false;
    //     this.sms_code = '';

    //     clearInterval(timer);
    //     this.codeSending = false;
    //     this.firstSend = true;
    // },
    // 获取详情
    getInfo() {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      this.$store
        .dispatch("user/getInfo")
        .then((res) => {
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 发送验证码
    handleSendCode() {
      if (!this.codeSending) {
        // 发送验证码
        this.timeCount = 60;
        this.codeSending = true;
        this.firstSend = false;
        clearInterval(timer);
        timer = setInterval(() => {
          if (this.timeCount >= 1) {
            this.timeCount -= 1;
          } else {
            clearInterval(timer);
            this.codeSending = false;
          }
        }, 1000);

        sendEmail({
          account: this.account,
          email: this.email,
          type: 1,
        });
      }
    },
    handleCopy(code) {
      let clipboard = new Clipboard(".copyBtn", {
        text: () => {
          return code;
        },
      });
      clipboard.on("success", () => {
        this.$message({
          message: this.$t("tip.copysuccess"),
          type: "success",
          center: true,
          customClass: "shotMsg",
        });
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.$message({
          message: this.$t("tip.copyerr"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
        clipboard.destroy();
      });
    },

    // 获取 NFT资产详情列表
    getDataNFT(id, address) {
      getNFTList({
        account: this.account,
        web: this.web,
        wallet_id: id,
      }).then((res) => {
        // console.log(res)
        // this.boxDataList[address]=res
        this.$set(this.boxDataList, address, res);
      });
    },
    // 展开 NFT资产详情列表
    showDetail(id, boxNum, type) {
      this.getDataNFT(id, boxNum); //获取 NFT资产详情列表

      var detailBox;
      if (type == "sub") {
        detailBox = this.$refs[boxNum][0];
      } else {
        detailBox = this.$refs[boxNum];
      }
      var height = detailBox.scrollHeight - detailBox.scrollTop;
      // 不存在 展开
      detailBox.style.height = height + "px";

      this.$nextTick(() => {
        setTimeout(() => {
          detailBox.style.height = "auto";
        }, 300);
      });
      this.boxNum.push(boxNum);
    },
    // 收起 NFT资产详情列表
    closeDetail(boxNum, type) {
      var detailBox;
      if (type == "sub") {
        detailBox = this.$refs[boxNum][0];
      } else {
        detailBox = this.$refs[boxNum];
      }
      var height = detailBox.scrollHeight - detailBox.scrollTop;

      detailBox.style.height = height + "px";
      // 存在 且相当 收起
      this.$nextTick(() => {
        setTimeout(() => {
          detailBox.style.height = "0";
        }, 0);
      });
      var index = this.boxNum.indexOf(boxNum);
      this.boxNum.splice(index, 1);
    },

    // 策略类型切换回调 - 分拨资金
    strategyChange(e) {
      if (e == 1) {
        this.$set(this.toolform, "money", undefined);
        this.$set(this.toolform, "float", 0);
      } else if (e == 2) {
        this.$set(this.toolform, "money", 0);
        this.$set(this.toolform, "float", 0);
      } else {
        this.$set(this.toolform, "money", undefined);
        this.$set(this.toolform, "float", undefined);
      }
    },
    // 策略类型切换回调 - 资金划转-多钱包资金划转
    strategyChange2(e) {
      if (e == 1) {
        this.$set(this.transfer_form, "money", undefined);
        this.$set(this.transfer_form, "float", 0);
      } else if (e == 2) {
        this.$set(this.transfer_form, "money", 0);
        this.$set(this.transfer_form, "float", 0);
      } else {
        this.$set(this.transfer_form, "money", undefined);
        this.$set(this.transfer_form, "float", undefined);
      }
    },

    // 切换展示钱包
    // 上一个
    pre() {
      if (this.selectNum > 1) {
        this.selectNum--;
      } else {
        this.selectNum = 3;
      }
    },
    // 下一个
    next() {
      if (this.selectNum < 3) {
        this.selectNum++;
      } else {
        this.selectNum = 1;
      }
    },
    // 获取资产信息
    async getWallets() {
      const res = await getWallets({
        web: this.web,
        account: this.account,
      });
      // console.log(res)

      if (!res) {
        this.$router.push({
          path: "/account",
        });
      } else {
        res.total_stat.erc20 = res.total_stat.erc20
          ? JSON.parse(res.total_stat.erc20)
          : {};
        res.total_stat.nft = res.total_stat.nft
          ? JSON.parse(res.total_stat.nft)
          : {};

        res.open_stat.erc20 = res.open_stat.erc20
          ? JSON.parse(res.open_stat.erc20)
          : {};
        res.open_stat.nft = res.open_stat.nft
          ? JSON.parse(res.open_stat.nft)
          : {};

        res.close_stat.erc20 = res.close_stat.erc20
          ? JSON.parse(res.close_stat.erc20)
          : {};
        res.close_stat.nft = res.close_stat.nft
          ? JSON.parse(res.close_stat.nft)
          : {};

        res.group_open_stat.erc20 = res.group_open_stat.erc20
          ? JSON.parse(res.group_open_stat.erc20)
          : {};
        // 总资产
        this.attached = res.total_stat;
        this.use_stat = res.open_stat;
        this.not_use_stat = res.close_stat;
        this.useStatList = res.open_stat.erc20 || {};
        this.groupOpenStatList = res.group_open_stat.erc20 || {};
        // 资金钱包
        var assetsArr = [];
        var balance_info = res.master_wallet.balance_info.erc20;
        this.masterWalletList = res.master_wallet.balance_info.erc20;
        if (!!balance_info && Object.keys(balance_info).length > 0) {
          for (var key in balance_info) {
            assetsArr.push(
              '<span style="color:#E65B1B">' +
                key.toUpperCase() +
                ": </span> " +
                balance_info[key] +
                "<br/>"
            );
          }
          res.master_wallet.assets = assetsArr.join("");
        } else {
          res.master_wallet.assets = '<span style="color:#E65B1B">/</span> ';
        }

        var nft_info = res.master_wallet.balance_info.nft;
        if (!!nft_info && Object.keys(nft_info).length > 0) {
          var assetsArrNFT = [];

          for (var key in nft_info) {
            assetsArrNFT.push(
              '<span style="color:#E65B1B">' +
                key.toUpperCase() +
                ": </span> " +
                nft_info[key] +
                "<br/>"
            );
          }
          res.master_wallet.assets_nft = assetsArrNFT.join("");
        }
        this.mainWallet = res.master_wallet;
        // console.log(this.mainWallet)
      }
    },

    // 获取分组列表
    async getGroupsList() {
      var res = await getGroups({
        account: this.account,
        web: this.web,
      });
      this.groupsListAll = res;
    },
    // 获取交易钱包列表-loading
    getSubWalletsListLoad() {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      this.getSubWalletsList()
        .then(() => {
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    // 获取交易钱包列表
    async getSubWalletsList(type) {
      const res = await getSubWalletsList({
        web: this.web,
        account: this.account,
        attached_source: this.source || 0,
        attached_trade: this.listRestriction || -1,
        sort_type: this.sortState,
        page: this.page,
        limit: this.pageNum,
        group_ids: this.groups.length > 0 ? this.groups.join(",") : "-999",
      });
      // console.log(res)
      this.groupsList = res.original_group_list;

      // 交易钱包
      res.list.forEach((item) => {
        var subAttached = [];
        var balance_info = item.balance_info.erc20;
        // var balance_info=item.balance_info

        if (!!balance_info && Object.keys(balance_info).length > 0) {
          for (var key in balance_info) {
            subAttached.push(
              '<span style="color:#E65B1B">' +
                key.toUpperCase() +
                ":</span> " +
                balance_info[key] +
                "<br/>"
            );
          }
          item.assets = subAttached.join("");
        } else {
          item.assets = '<span style="color:#E65B1B">/</span> ';
        }

        // NFT资产
        var assetsArrNFT = [];
        var nft_info = item.balance_info.nft;

        if (!!nft_info && Object.keys(nft_info).length > 0) {
          for (var key in nft_info) {
            assetsArrNFT.push(
              '<span style="color:#E65B1B">' +
                key.toUpperCase() +
                ": </span> " +
                nft_info[key] +
                "<br/>"
            );
          }
          item.assets_nft = assetsArrNFT.join("");
        }
      });

      this.count = res.count;
      this.enable = res.open_num;
      // 如果PC端则覆盖，移动端则push
      if (type == "push") {
        this.subWalletList.push(...res.list);
      } else {
        this.subWalletList = res.list;
      }

      if (this.isMobile == 0) {
        // 清除选中项
        // this.selectList = [];
      } else {
        //关闭加载更多
        if (this.pageNum * this.page >= this.count) {
          this.load_disabled = true;
        } else {
          this.load_disabled = false;
        }
      }
    },

    // tab 按钮
    openTool(tool) {
      if (tool == "wallet" && !this.email) {
        // 打开创建交易钱包时 未绑定邮箱 打开绑定邮箱弹窗
        this.$refs.emailBind.open();
        return;
      }
      if (tool == "pool") {
        // 后台刷新钱包数据
        dexWalletStatWeb({
          web: this.web,
          account: this.account,
          chain: this.mainWallet.chain,
        }).then((res) => {
          // 更新钱包数据
          this.getWallets();
          this.getSubWalletsList();
        });
      } else {
        this.restriction = 0; //创建交易钱包-默认交易限制-无限制
        this.$set(this.toolform, "strategy", 0);
        this.sourceAddress = this.$t("subManage.mainWallet"); //分拨资金-默认资金来源-资金钱包
      }
      if (tool == "wallet") {
        this.groupId = "0";
      } else {
        this.groupId = "-999";
      }
      this.selectTool = tool;
      this.showTool = true;
    },
    beforeClose(done) {
      this.tabIndex = 0;
      this.selectTool = "";
      this.addressValue = "";
      this.toolform = {};
      clearInterval(timer);
      this.codeSending = false;
      this.firstSend = true;
      done();
    },
    beforeClose2(done) {
      this.toolform = {};
      clearInterval(timer);
      this.codeSending = false;
      this.firstSend = true;
      done();
    },
    cancelClose() {
      this.selectTool = "";
      this.toolform = {};
      this.showTool = false;
    },
    // token符号查询给错误提示 并去空
    tokenFormat(token, labelType) {
      if (
        token.includes("_") ||
        token.includes("—") ||
        token.includes("/") ||
        token.includes("\\")
      ) {
        this.$nextTick(() => {
          this.$message({
            message: this.$t("tools.formatErr", { token: labelType }),
            type: "error",
            center: true,
            customClass: "shotMsg",
          });
        });
        throw new Error();
      } else {
        const trimmedString = token.trim();
        return trimmedString; // 如果需要返回去掉前后空格的字符串
      }
    },
    // 工具-提交
    submitTool() {
      this.$nextTick(() => {
        var inputList = document.querySelectorAll(".tabform input");
        var empty = false;
        try {
          inputList.forEach((item) => {
            if (!item.value) {
              throw "err";
            }
          });
        } catch {
          empty = true;
        }
        if (empty) {
          this.$message({
            message: this.$t("tip.dataErr"),
            type: "error",
            center: true,
            customClass: "shotMsg",
          });
        } else {
          // 提交
          const loading = Loading.service({
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "apploading topIndex",
          });
          if (this.selectTool == "wallet") {
            // Dex创建交易钱包
            createDexWallets({
              web: this.web,
              account: this.account,
              chain: this.mainWallet.chain,
              num: this.toolform.wallets,
              trade_type: this.restriction,
              email: this.email,
              sms_code: this.toolform.sms_code,
              group_id: this.groupId,
            })
              .then((res) => {
                // 0未创建成功 1全创建成功 2部分创建成功
                loading.close();

                // 初始化
                this.showTool = false;
                this.selectTool = "";
                this.toolform = {};
                this.getSubWalletsList();
                // 操作成功是否打开分拨资金
                this.$confirm(this.$t("tip.createSuc"), this.$t("tip.tips"), {
                  confirmButtonText: this.$t("tools.distribution.name"),
                  center: true,
                  showClose: false,
                  customClass: "deleteOk",
                  cancelButtonText: this.$t("common.back"),
                  dangerouslyUseHTMLString: true,
                }).then((res) => {
                  this.openTool("distribution");
                });
              })
              .catch((err) => {
                loading.close();
              });
          } else if (this.selectTool == "distribution") {
            // Dex分拨资金
            if (this.toolform.totalQuantity == 0) {
              this.$message({
                message: this.$t("tip.totalQuantity0"),
                type: "error",
                center: true,
                customClass: "shotMsg",
              });
              loading.close();
            }else if(Number(this.toolform.totalQuantity)>this.processDecimal(this.masterWalletList[this.toolform.currency])){
              this.$message({
                message: this.$t("tip.exceedBalance"),
                type: "error",
                center: true,
                customClass: "shotMsg",
              });
              loading.close();
            }else{
              dexDeliverToken({
                web: this.web,
                account: this.account,
                currency:
                  this.yenUnit1 && this.yenUnit2
                    ? this.yenUnit1 + "_" + this.yenUnit2
                    : "",
                chain: this.mainWallet.chain,
                token: this.toolform.currency,
                num: this.toolform.totalQuantity,
                strategy: this.toolform.strategy || 0, //策略
                ratio:
                  !!this.toolform.float && this.toolform.float != 0
                    ? this.toolform.float / 100
                    : this.toolform.float || 0,
                limit_num: this.toolform.money || 0,
                group_id: this.groupId,
              })
                .then((res) => {
                  loading.close();
                  // var res="deliver:Y6CSWVN12MP1KLBEVBXWPETJH644H3QO"
                  this.getScheduleData("distribution", res,1);

                  // 是否去查看日志
                  // this.$confirm(this.$t("tip.toSee"), this.$t("tip.tips"), {
                  //   confirmButtonText: this.$t("common.toSeeBtn"),
                  //   center: true,
                  //   showClose: false,
                  //   customClass: "deleteOk",
                  //   cancelButtonText: this.$t("common.back"),
                  // }).then((res) => {
                  //   this.$router.push({
                  //     path: "/robotlog",
                  //     query: {
                  //       web: this.web,
                  //       exchange: this.exchange,
                  //     },
                  //   });
                  // });
                  // 初始化
                  this.showTool = false;
                  this.selectTool = "";
                  this.toolform = {};
                  this.getWallets();
                  this.getSubWalletsList();
                })
                .catch((err) => {
                  loading.close();
                });
            }
          } else if (this.selectTool == "pool") {
            // 归集
            if (this.toolform.totalQuantity == 0) {
              this.$message({
                message: this.$t("tip.totalQuantity0"),
                type: "error",
                center: true,
                customClass: "shotMsg",
              });
              loading.close();

            }else if(
              (this.groupId == -999&&Number(this.toolform.totalQuantity)>this.processDecimal(this.useStatList[this.toolform.currency]))||
              (this.groupId != -999&&Number(this.toolform.totalQuantity)>this.processDecimal(this.groupOpenStatList[this.groupId][this.toolform.currency]))
            ){
              this.$message({
                message: this.$t("tip.exceedBalance"),
                type: "error",
                center: true,
                customClass: "shotMsg",
              });
              loading.close();
            }else{
              // Dex归集资金
              dexCollectToken({
                web: this.web,
                account: this.account,
                currency:
                  this.yenUnit1 && this.yenUnit2
                    ? this.yenUnit1 + "_" + this.yenUnit2
                    : "",
                chain: this.mainWallet.chain,
                token: this.toolform.currency,
                num: this.toolform.totalQuantity,
                group_id: this.groupId,
              })
                .then((res) => {
                  loading.close();
                  this.getScheduleData("pool", res,1);
                  // 是否去查看日志
                  // 初始化
                  this.showTool = false;
                  this.selectTool = "";
                  this.toolform = {};
                  this.getWallets();
                  this.getSubWalletsList();
                })
                .catch((err) => {
                  loading.close();
                });
            }
          } else if (this.selectTool == "empower") {
            if (this.toolform.token.length >= 20) {
              this.$message({
                message: this.$t("stocks.tokenNameTip"),
                type: "error",
                center: true,
                customClass: "shotMsg",
              });
              loading.close();
              return;
            }
            try {
              var approve_token = this.tokenFormat(
                this.toolform.token,
                this.$t("stocks.token")
              );
            } catch (err) {
              return;
            }

            // Dex新币授权
            dexWalletsApprove({
              web: this.web,
              account: this.account,
              approve_token,
              is_master_approve: 2,
              approve_source: 1,
              group_id: this.groupId,
            })
              .then((res) => {
                loading.close();
                // 授权成功
                this.$message({
                  message: this.$t("tip.authorizeSuc1"),
                  type: "success",
                  center: true,
                  customClass: "shotMsg",
                });
                // 初始化
                this.showTool = false;
                this.selectTool = "";
                this.toolform = {};
                this.getWallets();
                this.getSubWalletsList();
              })
              .catch((err) => {
                console.log(err);
                // 初始化
                this.showTool = false;
                this.selectTool = "";
                this.toolform = {};
                this.getWallets();
                this.getSubWalletsList();

                loading.close();
                if (err.code == 2174) {
                  //token合约未录入系统
                  this.$confirm(err.message, this.$t("tip.tips"), {
                    confirmButtonText: window.vm.$t("common.confirm2"),
                    // showConfirmButton:false,
                    // cancelButtonText: this.$t('common.back'),
                    showCancelButton: false,
                    center: true,
                    showClose: false,
                    customClass: "deleteOk",
                  }).then((r) => {});
                } else {
                  this.$confirm(
                    err.message +
                      '<br/><span style="color:#E65B1B">' +
                      this.$t("tip.authorizeErr1") +
                      "</span>",
                    this.$t("tip.tips"),
                    {
                      confirmButtonText: this.$t("common.toErrLog"),
                      cancelButtonText: this.$t("common.back"),
                      center: true,
                      showClose: false,
                      customClass: "deleteOk",
                      dangerouslyUseHTMLString: true,
                    }
                  ).then((r) => {
                    // 跳转错误日志 交易所、关键词-钱包批量授权
                    this.$router.push({
                      path: "/robotlog",
                      query: {
                        web: this.web,
                        exchange: this.exchange,
                        searchValue: "钱包批量授权",
                      },
                    });
                  });
                }
              });
          }
        }
      });
    },
    // 查询分拨归集进度
    getScheduleData(type, schedule_code,needLoad) {
      if(needLoad==1){
        this.loadingServiceCode=schedule_code
        this.loadingService=Loading.service({
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
          customClass: "apploading",
        });
      }
      getScheduleData({
        web: this.web,
        account: this.account,
        currency:
          this.yenUnit1 && this.yenUnit2
            ? this.yenUnit1 + "_" + this.yenUnit2
            : "",
        chain: this.mainWallet.chain,
        schedule_code,
      }).then((scheduleRes) => {
        // 如果能拿到进度了则隐藏loading
        if(scheduleRes.status!=0&&schedule_code==this.loadingServiceCode){
          this.loadingServiceCode=''
          this.loadingService.close()
        }
        
        // 分拨进度
        var use = Number(scheduleRes.use) || 0,
          fail = Number(scheduleRes.fail) || 0,
          skip = Number(scheduleRes.skip) || 0;
        this.$set(this.notifyProgressList, schedule_code, {
          progress: use + skip + fail,
          use,
          skip,
          fail,
          total: scheduleRes.total,
          type,
          status: scheduleRes.status,
        });
        // 0 初始化 1进行中 2完成 3失败 4频繁操作
        if (scheduleRes.status == 4) {
          this.$message({
            message: this.$t("tip.frequentTip"),
            type: "error",
            center: true,
            customClass: "shotMsg",
          });
        } else {
          if (scheduleRes.status == 0 || scheduleRes.status == 1) {
            var scheTime;
            if (scheduleRes.status == 1) {
              scheTime = 5000;
            } else {
              scheTime = 500;
            }
            // 轮询
            var scheduleTimeR = setTimeout(() => {
              this.getScheduleData(type, schedule_code);
            }, scheTime);
          }
          this.$once("hook:beforeDestroy", function () {
            clearTimeout(scheduleTimeR);
          });
        }
      }).catch(err=>{
        this.loadingServiceCode=''
        this.loadingService.close()
      });
    },
    // 关闭通知
    closeNotify(item, key) {
      if (item.status == 2) {
        // 如果已完成则可直接关闭
        this.$delete(this.notifyProgressList, key);
      } else {
        this.$confirm(
          item.type == "distribution"
            ? this.$t("tip.closeCollectionTip")
            : this.$t("tip.closeAllocationTip"),
          this.$t("tip.tips"),
          {
            confirmButtonText: this.$t("common.confirm2"),
            cancelButtonText: this.$t("common.back"),
            center: true,
            showClose: false,
            customClass: "deleteOk",
          }
        )
          .then(() => {
            this.$delete(this.notifyProgressList, key);
          })
          .catch((err) => {});
      }
    },
    jumpLog() {
      this.$router.push({
        path: "/robotlog",
        query: {
          web: this.web,
          exchange: this.exchange,
        },
      });
    },
    // 全部功能计算-如果有小数，舍去最后两位小数，避免分拨归集不干净问题
    processDecimal(data) {
      // 将数据转为字符串，并拆分为整数部分和小数部分
      let [integerPart, decimalPart] = data.toString().split('.');

      // 如果没有小数部分或者小数部分长度小于等于 2，则直接返回原数据
      if (!decimalPart || decimalPart.length <= 2) {
        return data;
      }

      // 如果小数部分长度大于 2，则截取小数部分前两位
      let processedData = parseFloat(integerPart + '.' + decimalPart.slice(0, -2));

      return processedData;
    },
    // 分拨-全部
    getAllDistribution() {
      if (this.toolform.currency) {
        this.$set(
          this.toolform,
          "totalQuantity",
          this.processDecimal(this.masterWalletList[this.toolform.currency])
        );
      }
    },
    // 归集-全部
    getAllPool() {
      if (this.toolform.currency) {
        if (this.groupId == -999) {
          this.$set(
            this.toolform,
            "totalQuantity",
            this.processDecimal(this.useStatList[this.toolform.currency])
          );
        } else {
          this.$set(
            this.toolform,
            "totalQuantity",
            this.processDecimal(this.groupOpenStatList[this.groupId][this.toolform.currency] || 0)
          );
        }
      }
    },
    // 打开导入交易钱包弹窗
    openImport() {
      if (!this.email) {
        // 打开导入交易钱包时 未绑定邮箱 打开绑定邮箱弹窗
        this.$refs.emailBind.open();
      } else {
        this.groupId = "0";
        this.restriction = 0; //导入交易钱包-默认交易限制-无限制
        this.selectTool = "importWallet";
        this.showImport = true;
      }
    },
    importClose() {
      this.selectTool = "";
      this.addressValue = "";
      this.showImport = false;

      this.toolform = {};
      clearInterval(timer);
      this.codeSending = false;
      this.firstSend = true;
    },
    importNext() {
      var addressArr = this.addressValue.split("\n");
      if (addressArr.length > 0 && addressArr[0] != "") {
        this.showImportEmail = true;
      } else {
        // 非空判定
        this.$message({
          message: this.$t("tip.privateKey"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      }
    },
    importBack() {
      this.showImportEmail = false;
      this.toolform = {};
      clearInterval(timer);
      this.codeSending = false;
      this.firstSend = true;
    },
    // 导入交易钱包-提交
    submitImport() {
      var addressArr = this.addressValue.split("\n");
      if (addressArr.length > 0 && addressArr[0] != "") {
        var wallet_import = JSON.stringify(addressArr);
        if (!this.toolform.sms_code) {
          // 验证码空
          this.$message({
            message: this.$t("tip.codeEmpty"),
            type: "error",
            center: true,
            customClass: "shotMsg",
          });
        } else {
          // 提交
          const loading = Loading.service({
            target: ".main",
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "apploading topIndex",
          });
          batchImport({
            account: this.account,
            web: this.web,
            wallet_import: wallet_import,
            trade_type: this.restriction,
            email: this.email,
            sms_code: this.toolform.sms_code,
            group_id: this.groupId,
          })
            .then((res) => {
              // 重新获取资产信息
              this.getWallets();
              this.getSubWalletsList();
              // this.$message({
              //     message: this.$t('tip.operationSuc'),
              //     type: 'success',
              //     center: true,
              //     customClass: 'shotMsg',
              // });
              // 初始化
              this.showImport = false;
              this.selectTool = "";
              this.addressValue = "";
              // 是否去查看日志
              this.$confirm(this.$t("tip.toSee"), this.$t("tip.tips"), {
                confirmButtonText: this.$t("common.toSeeBtn"),
                center: true,
                showClose: false,
                customClass: "deleteOk",
                cancelButtonText: this.$t("common.back"),
              }).then((res) => {
                this.$router.push({
                  path: "/robotlog",
                  query: {
                    web: this.web,
                    exchange: this.exchange,
                    searchValue: "batch_import",
                  },
                });
              });

              loading.close();
            })
            .catch((err) => {
              loading.close();
            });
        }
      } else {
        // 非空判定
        this.$message({
          message: this.$t("tip.privateKey"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      }
    },
    // Dex刷新交易钱包余额
    refresh() {
      this.$confirm(this.$t("tip.toRefresh"), this.$t("tip.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        center: true,
        showClose: false,
        customClass: "deleteOk",
        cancelButtonText: this.$t("common.back"),
      }).then((res) => {
        const loading = Loading.service({
          target: ".main",
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
          customClass: "apploading",
        });
        this.$message({
          message: this.$t("tip.processing"),
          type: "success",
          center: true,
          customClass: "shotMsg",
        });
        attachedWalletBalanceUpdate({
          web: this.web,
          account: this.account,
        })
          .then((res) => {
            loading.close();
            this.$message({
              message: this.$t("tip.refreshSuc"),
              type: "success",
              center: true,
              customClass: "shotMsg",
            });
            this.getWallets();
            this.getSubWalletsList();
          })
          .catch((err) => {
            loading.close();
          });
      });
    },
    // 资金钱包刷新
    mainRefresh() {
      this.$confirm(this.$t("tip.toMainRefresh"), this.$t("tip.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        center: true,
        showClose: false,
        customClass: "deleteOk",
        cancelButtonText: this.$t("common.back"),
      }).then((res) => {
        const loading = Loading.service({
          target: ".main",
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
          customClass: "apploading",
        });
        this.$message({
          message: this.$t("tip.processing"),
          type: "success",
          center: true,
          customClass: "shotMsg",
        });
        mainWalletBalanceUpdate({
          web: this.web,
          account: this.account,
        })
          .then((res) => {
            loading.close();
            this.$message({
              message: this.$t("tip.refreshSuc"),
              type: "success",
              center: true,
              customClass: "shotMsg",
            });
            this.getWallets();
          })
          .catch((err) => {
            loading.close();
          });
      });
    },
    // 打开资金划转弹窗
    openPopAssets(item, type) {
      //main-资金钱包 sub-交易钱包
      // 功能尚未开通，请联系客服！
      this.$confirm(this.$t("tip.waiting2"), this.$t("tip.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        center: true,
        showClose: false,
        customClass: "deleteOk",
        showCancelButton: false,
      })
        .then((res) => {})
        .catch((err) => {});
      return;

      // if(!this.email){
      //     打开绑定邮箱弹窗
      // 	this.$refs.emailBind.open()
      // }else{
      // console.log(item,type)
      this.transferType = type;
      this.sourceAddress = item.address;

      this.showTransfer = true;
      this.transfer_form.from_address = item.id;
      this.$set(this.transfer_form, "strategy", 0);
      this.attachedList = item.balance_info.erc20;
      var assetsStr = item.assets.replace(
        /<span style=\"color:#E65B1B\">/g,
        ""
      );
      var assetsArr = assetsStr.split(/:<\/span> |<br\/>|: <\/span> /);
      var assetsObj = {};
      for (var i = 0; i < assetsArr.length; i++) {
        if (i % 2 == 0 && assetsArr[i] != "") {
          assetsObj[assetsArr[i]] = assetsArr[i + 1];
        }
      }
      // console.log(assetsObj)
      this.assetsObj = assetsObj;
      // }
    },
    beforeCloseTransfer(done) {
      // 初始化
      this.transfer_form = {};
      this.tabIndex = 0;
      done();
    },
    // 资金划转-全部
    getAll() {
      // console.log(this.transfer_form.token)
      if (this.transfer_form.token) {
        this.$set(
          this.transfer_form,
          "num",
          this.assetsObj[this.transfer_form.token]
        );
      }
    },
    // 资金划转-提交
    submitTransfer() {
      // console.log( this.transfer_form.from_address)
      var inputList = document.querySelectorAll(".transfer_form input");
      var empty = false;
      try {
        inputList.forEach((item) => {
          if (!item.value) {
            throw "err";
          }
        });
      } catch {
        empty = true;
      }
      if (empty) {
        this.$message({
          message: this.$t("tip.dataErr"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else if (this.transfer_form.num == 0) {
        this.$message({
          message: this.$t("tip.num0"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else if (Number(this.transfer_form.num) > Number(this.assetsObj[this.transfer_form.token])) {
        this.$message({
          message: this.$t("tip.exceedBalance"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else if (
        this.tabIndex == 0 &&
        this.sourceAddress == this.transfer_form.address
      ) {
        this.$message({
          message: this.$t("subManage.targetAddressSame"),
          type: "error",
          center: true,
          customClass: "shotMsg",
        });
      } else {
        if (this.tabIndex == 0) {
          //单钱包划转
          // if(this.transfer_form.num>=1000){
          //     // 转账金额过大，需要邮箱验证，打开邮箱弹窗
          //     this.showEmailVer=true
          // }else{
          this.singleTransfer();
          // }
        } else {
          //钱包分拨
          // 提交
          const loading = Loading.service({
            target: ".main",
            text: "Loading",
            spinner: "el-icon-loading",
            background: "rgba(0, 0, 0, 0.7)",
            customClass: "apploading topIndex",
          });
          dexDeliverToken({
            web: this.web,
            account: this.account,
            currency:
              this.yenUnit1 && this.yenUnit2
                ? this.yenUnit1 + "_" + this.yenUnit2
                : "",
            chain: this.mainWallet.chain,
            token: this.transfer_form.token,
            num: this.transfer_form.num,
            strategy: this.transfer_form.strategy || 0, //策略
            ratio:
              !!this.transfer_form.float && this.transfer_form.float != 0
                ? this.transfer_form.float / 100
                : this.transfer_form.float || 0,
            limit_num: this.transfer_form.money || 0,
            type: 1, //分拨类型 0-默认分拨资金钱包、1-指定钱包分拨
            from_wallet: this.transfer_form.from_address, //指定分拨钱包id
          })
            .then((res) => {
              // 是否去查看日志
              this.$confirm(this.$t("tip.toSee"), this.$t("tip.tips"), {
                confirmButtonText: this.$t("common.toSeeBtn"),
                center: true,
                showClose: false,
                customClass: "deleteOk",
                cancelButtonText: this.$t("common.back"),
              }).then((res) => {
                this.$router.push({
                  path: "/robotlog",
                  query: {
                    web: this.web,
                    exchange: this.exchange,
                    searchValue: "dex_transfer",
                  },
                });
              });
              // 初始化
              this.showTransfer = false;
              this.transfer_form = {};
              this.getWallets();
              this.getSubWalletsList();
              loading.close();
            })
            .catch((err) => {
              loading.close();
            });
        }
      }
    },
    // 单钱包划转
    singleTransfer() {
      // 提交
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading topIndex",
      });
      subTransfer({
        web: this.web,
        account: this.account,
        from_address: this.transfer_form.from_address,
        to_address: this.transfer_form.address,
        token: this.transfer_form.token,
        num: this.transfer_form.num,
        // sms_code: this.transfer_form.sms_code
      })
        .then((res) => {
          // 是否去查看日志
          this.$confirm(this.$t("tip.toSee"), this.$t("tip.tips"), {
            confirmButtonText: this.$t("common.toSeeBtn"),
            center: true,
            showClose: false,
            customClass: "deleteOk",
            cancelButtonText: this.$t("common.back"),
          }).then((res) => {
            this.$router.push({
              path: "/robotlog",
              query: {
                web: this.web,
                exchange: this.exchange,
                searchValue: "dex_transfer",
              },
            });
          });
          // 初始化
          this.showTransfer = false;
          this.transfer_form = {};
          this.getWallets();
          this.getSubWalletsList();
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
    // 交易钱包排序（默认0/启用优先1/停用优先2）
    switchSort() {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading topIndex",
      });
      // 点击排序，清除交易钱包列表，回到第一页
      this.subWalletList = [];
      this.page = 1;
      if (this.sortState == 0 || this.sortState == 2) {
        this.sortState = 1;
      } else if (this.sortState == 1) {
        this.sortState = 2;
      }
      this.getSubWalletsList()
        .then(() => {
          loading.close();
        })
        .catch(() => {
          loading.close();
        });
    },
    // 开关
    switchChange(item) {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      setAttached({
        account: this.account,
        wallet_id: item.id,
        type: 1,
        state: item.state,
      })
        .then((res) => {
          this.$message({
            message: this.$t("tip.operationSuc"),
            type: "success",
            center: true,
            customClass: "shotMsg",
          });

          this.getWallets();
          this.getSubWalletsList();
          loading.close();
        })
        .catch((err) => {
          item.state = item.state == "1" ? "0" : "1";
          loading.close();
        });
    },
    // 开关-移动端
    switchChangePhone(item) {
      const loading = Loading.service({
        target: ".main",
        text: "Loading",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
        customClass: "apploading",
      });
      setAttached({
        account: this.account,
        wallet_id: item.id,
        state: item.state == "1" ? "0" : "1",
      })
        .then((res) => {
          this.$message({
            message: this.$t("tip.operationSuc"),
            type: "success",
            center: true,
            customClass: "shotMsg",
          });
          // item.state=item.state=='1'? '0':'1'

          this.getWallets();
          this.getSubWalletsList();
          loading.close();
        })
        .catch((err) => {
          loading.close();
        });
    },
  },
  beforeDestroy() {
    if (timer) {
      clearInterval(timer);
      timer = "";
    }
    // 子组件销毁
    if (this.$refs.emailBind) {
      this.$refs.emailBind.beforeDestroy();
    }
  },
};
</script>